@font-face {
    font-family: "RegularItalic";
    src: url(./fonts/fontsNew/Abril_Display_SemiBoldItalic.otf);
    font-weight: 100;
    /* font-display: swap; */
}

@font-face {
    font-family: Regular;
    src: url(./fonts/Al-Jazeera-Arabic-Regular.ttf);
    font-weight: 500;
    /* font-display: swap; */
}

@font-face {
    font-family: "RegularBodyRoman";
    src: url(./fonts/MyriadProRegular.ttf);
    font-weight: 500;
    font-display: swap;
}

* {
    direction: inherit;
    -webkit-animation: fadeIn 0.5s;
    animation: fadeIn 0.5s;
    /* font-family: Regular; */
    outline: none !important;
    font-weight: 100 !important;
}

p {
    font-size: 16px;
}
.currency {
    font-size: 15px !important;
    color: #fff !important;
}

@media (max-width: 425px) {
    .currency {
        font-size: 12px !important;
    }
}

.ProfileSubmit .btn-primary,
.form-control,
label,
span {
    font-size: 14px;
}

a {
    font-size: 14px;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
    font-size: 14px;
    vertical-align: inherit !important;
}

body {
    overflow-x: hidden;
    font-family: "RegularItalic" !important;
}

.App-logo {
    height: 40vmin;
}

/* width */

body::-webkit-scrollbar {
    width: 7px;
}

/* Track */

body::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */

body::-webkit-scrollbar-thumb {
    background: #229d8c;
}

/* Handle on hover */

body::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #09d3ac;
}

ul {
    text-align: initial;
    padding-left: 40;
    list-style: none;
    padding: 0;
    margin: 0;
}

.no_pad {
    padding: 0 !important;
}

/* 
.Header {
  background: #229d8c;
  padding-top: 8px;
  padding-bottom: 8px;
}

.DropDownLists .HeaderList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-inline-box;
}

.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #0000;
  border-color: #0000;
}

.DropDownLists .HeaderList .HeaderListLi {
  margin-right: 27px;
}

.DropDownLists .btn {
  border-radius: 0px;
  background: none;
  border: none;
  box-shadow: none;
  outline: none;
  padding: 0px;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
  color: #fff;
  background: none;
  border-color: none;
  outline: none;
}

.dropdown span {
  margin-left: 5px;
  border: 1px solid #fff;
  height: 20px;
  text-align: center;
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 13px;
} */

a {
    text-decoration: none;
    cursor: pointer;
}

/* .dropdown-menu {
  min-width: 100%;
  padding: 0px 0;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  background-color: #229d8c;
  background-image: -webkit-linear-gradient(top, #229d8c 0, #229d8c 100%);
  background-image: -o-linear-gradient(top, #229d8c 0, #229d8c 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f5f5f5),
    to(#e8e8e8)
  );
  background-image: linear-gradient(to bottom, #229d8c 0, #229d8c 100%);
  background-repeat: repeat-x;
  color: #fff;
}

.navbar-inverse {
  background-image: none;
  background-repeat: repeat-x;
  border-radius: 4px;
  border: none;
  background-color: #0000;
  color: #fff;
  padding-top: 0px;
}

.navbar {
  position: relative;
  margin-bottom: 0;
  min-height: auto;
  border: 1px solid #0000;
  float: left;
}

.navbar-collapse {
  padding-left: 0;
}

.navbar-inverse .navbar-brand,
.navbar-inverse .navbar-nav > li > a {
  text-shadow: none;
  color: #fff;
}

.navbar-toggle {
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  background-color: #0000;
  background-image: none;
  border: 1px solid #0000;
  border-radius: 1px;
}

.Header .nav > li > a {
  position: relative;
  display: block;
  text-transform: uppercase;
  padding: 0px 20px 0px 0;
}

.DropDownLists {
  float: right;
  text-align: right;
}
.SearchAndLogo {
  padding-top: 13px;
  padding-bottom: 13px;
  border-bottom: 1px solid #dedede;
  margin-bottom: 21px;
}

.SearchAndLogo .Logo img {
  width: auto;
  height: 49px;
}

.SearchHeader .FilterInput {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 39px;
  border-right: none;
  color: #bebebe;
}

.SearchHeader .dropdown-toggle {
  font-size: 16px;
  background: none;
  border: 1px solid #ccc;
  box-shadow: none;
  height: 39px;
  border-radius: 0;
  padding: 2px 21px;
  color: #bebebe;
}

.SearchHeader .dropdown-menu {
  background-color: #f3f3f3;
}

.SearchHeader .dropdown-menu li {
  border-bottom: 1px solid #d0d0d0;
  padding: 3px 0;
}

.SearchHeader .SearchIcon {
  background: #229d8c;
  color: #fff;
  height: 39px;
  padding: 2px 14px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align: center;
  outline: none;
  border: 1px solid #229d8c;
}

.input-group-btn > .btn:active,
.input-group-btn > .btn:focus,
.input-group-btn > .btn:hover {
  outline: none;
}

.form-control:focus {
  border-color: #229d8c;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px #229d8c5c, 0 1px 2px #229d8c6b;
}

.SearchHeader .input-group {
  margin-top: 6px;
}

.CartButtons .LoginUL {
  list-style: none;
  display: -webkit-inline-box;
  margin: 0;
}

.CartButtons .LoginUL .LiCircle {
  padding: 11px;
  border: 1px solid #ccc;
  border-radius: 34px;
  text-align: center;
  margin-left: 13px;
  margin-right: 13px;
}

.CartButtons .LoginUL .LiCircle .Counter {
  position: absolute;
  top: 0;
  background: #229d8c;
  width: 20px;
  height: 20px;
  margin-left: 0px;
  border-radius: 50%;
  color: #fff;
}

.AccountLogin .AccountButton {
  background: none;
  border: none;
  color: #a4a4a4;
  box-shadow: none;
  text-shadow: none;
  font-size: 15px;
  padding: 0;
}

.AccountLogin .dropdown span {
  border: 1px solid #a4a4a4;
}

.AccountLogin .btn-primary.active,
.AccountLogin .btn-primary:active,
.AccountLogin .open > .dropdown-toggle.btn-primary {
  color: #1a9c8c;
  background-color: #0000;
  border-color: #0000;
}

.AccountLogin a {
  text-decoration: none;
  color: #a4a4a4;
}

.AccountLogin {
  color: #c9c9c9;
  text-transform: capitalize;
}

.AccountLogin a:hover {
  color: #1a9c8c;
} */

.TodayDeals {
    background: #f7f7f7;
    margin-bottom: 20px;
    border-radius: 29px;
}

.TodayDeals h1 {
    background: #1a9c8c;
    color: #fff;
    padding: 17px;
    border-top-left-radius: 29px;
    border-top-right-radius: 29px;
    font-size: 23px;
    text-align: center;
}

.DealBox {
    display: flex;
    width: 100%;
}

a:hover {
    text-decoration: none;
}

.DealBox img {
    border: 2px solid #f7f7f7;
    padding: 8px;
    border-top-right-radius: 0;
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
    width: 100%;
    height: inherit;
}

.Block2 .DealBox img {
    width: 160px;
    height: 150px;
}

.ProductNameInBox {
    border: 2px solid #f7f7f7;
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
    padding-left: 11px;
    padding-right: 19px;
    width: 100%;
}

.ProductNameInBox h3 {
    font-size: 14px;
    color: #1aa596;
    overflow: hidden;
    text-overflow: inherit;
    white-space: nowrap;
    width: 215px;
    padding-bottom: 8px;
    display: -webkit-box;
    text-transform: capitalize;
}

.ProductNameInBox h4 {
    color: #a4a4a4;
}

.carousel .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: center;
    border-radius: 32px;
    margin-top: 22px;
    background: #f0f0f0;
    padding: 60px 71px;
}

.carousel.carousel-slider .control-arrow:hover {
    background: #0000;
}

.carousel.carousel-slider .control-arrow {
    opacity: 1;
    color: #1a9c8c;
}

.carousel .control-next.control-arrow:before {
    border-left: 12px solid #1a9c8c;
}

.SlideContent {
    text-align: left;
}

.carousel .slide h5 {
    background: #1aa596;
    padding: 7px 16px;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    margin-top: 0px;
    color: #fff;
    border-radius: 21px;
    margin-right: auto;
}

.LoginForm .Wrong span {
    color: #5a0b0b;
    font-size: 15px;
    background: #dc7e7e;
    padding: 6px 52px 9px;
    border-radius: 14px 0;
}

.carousel .slide h2 {
    color: #b75700;
    font-size: 32px;
}

.carousel .slide h4 {
    color: #777777;
    font-size: 15px;
    line-height: 1.4;
}

.carousel .slide span {
    color: #1a9c8c;
    font-size: 12px;
}

.carousel .slide h3 {
    color: #a4a4a4;
    margin-bottom: 17px;
}

/* .carousel .slide a {
  text-decoration: none;
  border: 1px solid #1aa596;
  color: #1aa596;
  padding: 4px 12px;
  border-radius: 21px;
  font-size: 13px;
}

.carousel .slide a:hover {
  border: 1px solid #1aa596;
  color: #fff;
  background: #1aa596;
  transition: 0.5s;
} */

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
    margin: 0 9px;
    display: inline-block;
    border-top: 12px solid #0000;
    border-bottom: 12px solid #0000;
    content: "";
}

.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #1a9c8c;
}

.NEWARRIVED {
    margin-top: 20px;
    background: #f7f7f7;
    padding: 54px 0px;
}

.NEWARRIVED h1 {
    color: #1a9c8c;
    font-size: 22px;
}

.NEWARRIVED h4 {
    color: #a4a4a4;
    font-size: 16px;
    margin-top: 18px;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 10px;
    text-transform: capitalize;
}

.NEWARRIVED .ViewProduct a {
    background: #f7f7f7;
    padding: 7px 16px;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    margin-top: 43px;
    color: #1aa596;
    border-radius: 21px;
    text-decoration: none;
    border: 1px solid #1aa596;
}

.NEWARRIVED .ViewProduct a:hover {
    background: #1aa596;
    color: #fff;
    transition: 0.5s;
}

.CartModal .btn-primary:first-child {
    background-image: none;
    background: #1aa596;
    background-repeat: repeat-x;
    border-color: #1aa596;
    color: #fff;
}

.CartModal .btn-primary:first-child:hover {
    transition: 0.5s;
    background: #fff;
    color: #1aa596;
}

.CartModal .btn-primary:last-child {
    background-image: none;
    background: #a4a4a4;
    background-repeat: repeat-x;
    border-color: #a4a4a4;
    color: #fff;
}

.CartModal .btn-primary:last-child:hover {
    transition: 0.5s;
    background: #fff;
    color: #a4a4a4;
}

.LocationModal .btn-primary:first-child {
    background-image: none;
    background: #1aa596;
    background-repeat: repeat-x;
    border-color: #1aa596;
    color: #fff;
    padding: 6px 29px;
}

.LocationModal .modal-header {
    padding: 20px !important;
}

.LocationModal .btn-primary:first-child:hover {
    transition: 0.5s;
    background: #fff;
    color: #1aa596;
}

.LocationModal .btn-primary:last-child {
    background-image: none;
    background: #a4a4a4;
    background-repeat: repeat-x;
    border-color: #a4a4a4;
    color: #fff;
    padding: 6px 29px;
}

.LocationModal .btn-primary:last-child:hover {
    transition: 0.5s;
    background: #fff;
    color: #a4a4a4;
}

.EmptyCart {
    text-align: center;
}

.EmptyCart img {
    max-width: 100%;
    width: 45%;
}

.CartModal .modal-dialog {
    width: 600px;
    margin: 8% auto;
}

.CartModal ul li {
    display: flex;
    border-bottom: 1px solid #e4e4e4;
    width: 100%;
    padding: 1px 7px 15px;
}

.CartModal ul {
    list-style: none;
    padding: 1px 19px 0 0;
    height: 320px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.phone-input__content {
    /* background-color: #fff; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid #e0e0e0; */
    /* border-radius: 7px !important; */
    /* height: 41px !important; */
    /* display: block; */
    width: 33%;
    height: 40px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #1a9c8c;
    background-color: #fafafa;
    border: 1px solid #d5d5d5;
    border-radius: 13px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 18px;
}
.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
    border-right: none;
    border-top: none;
    border-bottom: none;
}
.phone-input__content__country--code {
    padding: 10px;
    border-right: 1px solid #e0e0e0;
    color: #707070;
}
.phone-input__content__container {
    background-color: transparent;
    border: none !important;
}
.input-group {
    background-color: transparent;
}

.CartModal ul li span {
    background: #1aa596;
    padding: 2px 11px 12px 13px;
    margin: 0 26px 0 0;
    border-radius: 6px;
    color: #fff;
    line-height: 1.7;
    letter-spacing: 2px;
}

.CartModal ul li h5 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    color: #4f5864;
    margin: 0;
    line-height: 1.4;
    width: 90%;
    padding: 12px 0 0;
}

.CartModal ul li a {
    background: #1aa596;
    color: #fff;
    padding: 5px 9px;
    text-align: center;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 29px;
    width: 118px;
    box-shadow: 0 0 6px hsla(174, 73%, 37%, 0.41);
    animation: 3s linear 1s infinite alternate;
}

.CartProdImg,
.FavTab img {
    height: 100px;
    width: 136px;
    border-radius: 18px;
    margin-right: 10px;
    object-fit: scale-down;
}

.LocationModal .modal-body {
    height: 450px;
    overflow-y: scroll;
    padding: 15px !important;
}

/* width */

.CartModal ul::-webkit-scrollbar,
.LocationModal .modal-body::-webkit-scrollbar {
    width: 5px;
}

/* Track */

.CartModal ul::-webkit-scrollbar-track,
.LocationModal .modal-body::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */

.CartModal ul::-webkit-scrollbar-thumb,
.LocationModal .modal-body::-webkit-scrollbar-thumb {
    background: #1aa596;
}

/* Handle on hover */

.CartModal ul::-webkit-scrollbar-thumb:hover,
.LocationModal .modal-body::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.CartModal ul li a:hover {
    background: #fff;
    transition: 0.5s;
    color: #1aa596;
}

.ViewProduct {
    margin-top: 26px;
    text-align: right;
}

.owl-carousel .owl-item img {
    display: block;
    width: auto;
}

.ProductsBox .form-control {
    height: 39px;
    border-radius: 38px;
    width: 50%;
}

.owl-carousel .owl-item .OwlMainImg {
    display: block;
    width: 100%;
    margin-left: auto;
    height: 150px;
    margin-right: auto;
    border-radius: 18px;
    object-fit: cover;
}

.owl-carousel {
    margin-top: 35px;
    z-index: 0;
}

.ProductBox {
    background: #fff;
    padding: 18px 0 0;
    text-align: center;
    border-radius: 20px;
    margin-top: 4px;
    margin-bottom: 4px;
    margin: 9px;
}

.carousel .thumb {
    border-radius: 11px !important;
    padding: 0;
}

.carousel .thumb img {
    vertical-align: top;
    height: 91px !important;
    padding: 3px !important;
    object-fit: cover !important;
    border-radius: 11px !important;
}

.carousel .thumbs {
    text-align: center !important;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
    border: 3px solid #1a9c8c;
    border-radius: 11px;
}

.ProductBox:hover {
    box-shadow: 0 0 9px #1aa59663;
    transition: 0.5s;
}

.ProductsBox .thumbnail:hover {
    box-shadow: 0 0 10px #1aa5968c;
    transition: 0.5s;
}

.swal-button {
    background-color: #1aa596;
}

.swal-button:hover {
    background-color: #1aa596cc !important;
}

.OwlDemoImageContainer {
    text-align: center;
    border: 1px solid #e3e2e2;
    padding: 13px;
    border-radius: 20px;
    margin: 4px 21px;
}

.ProductBox ul {
    list-style: none;
    display: -webkit-box;
    border-top: 1px solid #e3e2e2;
    padding: 0;
    width: 100%;
}

.ProductBox ul li {
    width: 50%;
    border-right: 1px solid #e3e2e2;
    padding: 18px;
    text-align: center;
}

.ProductBox ul li:last-child {
    border-right: none;
}

.ProductBox ul li a {
    text-decoration: none;
    color: #a4a4a4;
    display: -webkit-inline-box;
}

.ProductBox ul li a:hover {
    color: #1aa596;
}

.ProductBox .UlLiMenu {
    margin-right: 6px !important;
}

.RelatedH1 h1 {
    margin-bottom: 32px;
    margin-left: 17px;
}

.HomeSellProducts {
    background: #1aa596;
    color: #fff;
    padding-top: 20px;
    padding-bottom: 54px;
    margin-bottom: 50px;
}

.HomeSellProducts h1 {
    font-size: 57px;
    padding-top: 66px;
    margin-bottom: 0;
}

.HomeSellProducts strong {
    font-size: 83px;
}

.HomeSellProducts a {
    color: #fff;
    border: 1px solid #fff;
    padding: 9px 38px;
    border-radius: 25px;
    font-size: 17px;
    text-decoration: none;
}

.HomeSellProducts a:hover {
    color: #1aa596;
    background-color: #fff;
    border: 1px solid #fff;
    transition: 0.5s;
}

.ButtonContainer {
    margin-top: 30px;
}

.POPULAR h1 {
    color: #1a9c8c;
    font-size: 16px;
}

.POPULAR h4 {
    color: #a4a4a4;
    font-size: 16px;
    margin-top: 33px;
}

.ZoomSlider {
    width: 100%;
}

.react-slider img {
    border: thin solid #ccc;
    border-radius: 14px;
}

.react-slider__container button {
    width: 30px;
    height: 30px;
    background: #229d8c;
}

.react-slider__btnPrev {
    left: 12px;
}

.react-slider__btnNext {
    right: 12px;
}

.react-slider__ul li.active img {
    border: 2px solid #229d8c;
    border-radius: 9px;
}

.react-slider__ul li img {
    border: 2px solid #ccc;
    border-radius: 9px;
}

.ZoomWrapp {
    padding: 7px;
}

.POPULAR .ViewProduct a {
    background: #fff;
    padding: 7px 16px;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    margin-top: 43px;
    color: #1aa596;
    border-radius: 21px;
    text-decoration: none;
    border: 1px solid #1aa596;
}

.POPULAR .ViewProduct a:hover {
    background: #1aa596;
    color: #fff;
    transition: 0.5s;
}

.popularProducts {
    margin-top: 38px;
    margin-bottom: 45px;
}

.ProductsBox {
    margin-top: 0px;
    margin-bottom: 30px;
}

.ProductsBox .thumbnail {
    display: block;
    padding: 14px;
    margin-bottom: 32px;
    line-height: 1.42857143;
    background-color: #fff;
    border: 1px solid #f7f7f7;
    border-radius: 19px;
    -webkit-transition: border 0.2s ease-in-out;
    -o-transition: border 0.2s ease-in-out;
    text-align: center;
    transition: border 0.2s ease-in-out;
}

.ProductsBox a,
.ProductsBox a:hover {
    text-decoration: none;
}

.ProductsBox .thumbnail h3 {
    color: #817d7d;
    font-size: 15px;
    text-align: center;
    line-height: 1.4;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
}

.ProductsBox .thumbnail a > img,
.ProductsBox .thumbnail > img {
    margin-right: auto;
    margin-left: auto;
    height: 150px;
    width: auto;
    border-radius: 18px;
}

.ProductsBox .thumbnail span {
    color: #a4a4a4;
    font-size: 18px;
    text-align: center;
    display: -webkit-inline-box;
}

.ProductsBox .thumbnail span h6 {
    margin-top: 0px;
    padding: 0 5px;
}

.ProductsBox ul {
    list-style: none;
    margin: 0;
    margin-top: 6px;
    padding: 0;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.ProductsBox ul a {
    background: #fff;
    padding: 6px 15px;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    color: #1aa596;
    border-radius: 21px;
    text-decoration: none;
    border: 1px solid #1aa596;
    margin: 4px;
    display: -webkit-inline-box;
}

.ProductsBox ul li:last-child a {
    background: #fff;
    color: #f44336;
    border: 1px solid #f44336;
}

.ProductsBox ul a:hover {
    background: #1aa596;
    color: #fff;
    transition: 0.5s;
}

.ProductsBox ul li:last-child a:hover {
    background: #f44336;
    color: #fff;
    transition: 0.5s;
}

.ProductsBox ul li a.Favoured {
    background: #f44336;
    color: #fff;
}

.ProductsBox ul li {
    width: 50%;
    display: inline;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.FavIcon {
    position: absolute;
    right: 0;
    top: 23px;
}

.price h4 {
    margin-top: 0;
}

.SooqSpac ul {
    list-style: none;
    display: -webkit-inline-box;
    width: 100%;
    padding: 0;
    border: 2px solid #f7f7f7;
    margin: 0;
    border-radius: 16px;
    margin-bottom: 60px;
}

.SooqSpac ul li {
    text-align: center;
    padding-bottom: 0;
    margin-bottom: 0;
    overflow: hidden;
    border-right: 2px solid #f7f7f7;
    padding-top: 19px;
    padding-bottom: 19px;
    width: 25%;
}

.SooqSpac ul li:last-child {
    border-right: none;
}

.SooqSpac ul li h2 {
    margin-bottom: 0;
    color: #1a9c8c;
    font-size: 19px;
    font-weight: 100;
}

.SooqSpac ul li img {
    width: auto;
    height: 73px;
}

.Footer {
    background: #f8f8f8;
    padding-top: 11px;
    padding-bottom: 14px;
}

.SubFooter {
    padding-top: 16px;
    color: #a4a4a4;
    font-weight: lighter;
}

.FooterSocialList {
    list-style: none;
    margin-top: 26px;
    text-align: center;
    padding: 0;
    display: -webkit-inline-box;
}

.FooterSocialList li {
    width: 43px;
    height: 43px;
    background: #fff;
    text-align: center;
    padding-top: 7px;
    border-radius: 50%;
    margin-right: 5px;
}

.FooterSocialList li:hover {
    background: #1a9c8c;
}

.FooterSocialList li a {
    color: #a4a4a4;
    font-size: 20px;
}

.FooterSocialList li:hover a {
    color: #fff;
}

.ContactFooterList ul {
    list-style: none;
    padding: 0;
    margin-top: 24px;
    color: #a4a4a4;
    font-size: 17px;
}

.Footer h4 {
    color: #1a9c8c;
    font-size: 16px;
    font-weight: 600;
}

.Footer p {
    margin: 0;
    font-weight: lighter;
}

.Footer a {
    text-decoration: none;
    color: #a4a4a4;
    font-size: 16px;
    text-transform: capitalize;
}

.Footer a:hover {
    color: #1a9c8c;
}

.Footer img {
    width: 70%;
}

.TwoColsCategories {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 131px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    column-count: 2;
    margin-top: 25px;
    width: 80%;
}

.TwoColsCategories li {
    padding-bottom: 2px;
}

.AccountList ul {
    margin-top: 20px;
}

.MainFooter {
    border-bottom: 1px solid #939393;
    padding-bottom: 35px !important;
    padding-top: 15px !important;
}

.Footer .SubFooter img {
    width: 47px;
    margin-left: 29px;
}

.Footer .SubFooter ul {
    display: -webkit-inline-box;
}

.Form .form-control {
    display: block;
    width: 33%;
    height: 37px !important;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #1a9c8c;
    background-color: #fafafa;
    border: 1px solid #d5d5d5;
    border-radius: 13px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 18px;
}

.custom-select {
    display: block;
    width: 33%;
    height: 40px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #707070;
    background-color: #fafafa;
    border: 1px solid #d5d5d5;
    border-radius: 13px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 18px;
}
.LoginForm {
    text-align: center;
    padding-top: 12px;
    padding-bottom: 40px;
}
.LoginForm .row {
    justify-content: center;
}

.LoginForm h3 {
    color: #707070;
    margin-top: 41px;
    font-weight: 500;
    text-transform: capitalize;
}

.LoginForm h1 {
    font-size: 27px;
    color: #707070;
    margin-bottom: 13px;
    font-weight: 600;
    margin-top: 4px;
    text-transform: capitalize;
}

.CardsFooter {
    text-align: end;
}

.FooterLOgo {
    margin-top: 7px;
}

.LoginForm span {
    color: #707070;
    font-size: 15px;
    text-transform: capitalize;
}

.Form {
    margin-top: 34px;
}

.Form a {
    font-size: 14px;
}

.Form .btn-primary {
    background-image: none;
    background-color: #1a9c8c;
    border-color: #1a9c8c;
    width: 32%;
    margin-top: 15px;
    border-radius: 50px;
    height: 42px;
    font-size: 16px;
}

.carousel .slide img {
    border-radius: 18px;
}

.carousel .slide img {
    width: 100%;
    vertical-align: top;
    border: 0;
    height: 395px;
    object-fit: cover;
}

.TableImage img {
    padding-top: 40px;
}

.Form .btn-primary:hover {
    background-image: none;
    background-color: #fff;
    border-color: #1a9c8c;
    color: #1a9c8c;
    transition: 0.5s;
}

.SocialLogin h2 {
    color: #707070;
    margin-top: 80px;
    text-transform: capitalize;
}

.SocialLogin ul {
    display: -webkit-inline-box;
}

.SocialLogin ul li {
    margin-left: 12px;
    margin-top: 13px;
}

.GoogleLoginIcon {
    background-color: #fff;
    display: inline-flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: none !important;
    border-radius: 32px !important;
    border: 1px solid #f44336 !important;
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    outline: none !important;
    padding: 0px 27px !important;
}
@media (max-width: 375px) {
    .GoogleLoginIcon {
        padding: 0 10px !important;
    }
    .GoogleLoginIcon span {
        padding: 5px !important;
    }
    .GoogleLoginIcon div {
        margin: 0 !important;
        padding: 3px !important;
    }
    .kep-login-facebook.metro {
        /* padding: 0 !important; */
        max-width: 20px !important;
    }
}
.modal-add-loc__ {
    overflow-y: scroll !important;
}

.modal-footer .form-group .loc-btn {
    padding: 10px !important;
    font-weight: 500 !important;
    width: 100% !important;
}
.ResPas-container {
    display: flex;
    justify-content: flex-end;
}
.ResPas {
    height: 30px !important;
    background-color: #09d3ac;
    transition: ease-in-out all 0.3s;
    color: white;
}
.ResPas:hover {
    background-color: #198d7e;
    transition: ease-in-out all 0.3s;
    color: white;
}
.ResPas:focus {
    color: #fff !important;
}
.facebookLgin {
    background: #fff;
    border: 1px solid #3f51b5;
    padding: 11px 31px;
    border-radius: 32px;
    outline: none !important;
}

.facebookLgin:hover {
    background: #3f51b5;
    border: 1px solid #3f51b5;
    color: #fff;
    transition: 0.5s;
}

.GoogleLoginIcon:hover {
    /* background: #f44336 !important; */
    border: 1px solid #f44336 !important;
    color: #fff !important;
    transition: 0.5s;
}

.GoogleLoginIcon:hover span {
    color: red !important;
    transition: 0.5s;
}
.facebookLgin i {
    padding-right: 18px;
}

.ProfileBackImage {
    text-align: center;
    background-image: url(/img/profileback.png);
    background-size: cover;
    padding-top: 31px;
    padding-bottom: 30px;
    border: 1px solid #d5d5d5;
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    margin-top: 10px;
}

.ProfileBackImage h2 {
    color: #1a9c8c;
}

.ProfileBackImage img {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    object-fit: cover;
}

.Profile {
    font-family: "RegularBodyRoman";
}

.Profile .nav-tabs {
    border: 1px solid #d5d5d5;
    padding-top: 0px;
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
}

.Profile .CartTabs {
    border: 1px solid #d5d5d5;
    padding-top: 0px;
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
    border-top-right-radius: 22px;
    border-top-left-radius: 22px;
    margin: 20px 0;
}

.Profile .nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
    color: #1f9e97;
    cursor: default;
    background-color: #fff;
    border: none;
    border-bottom-color: transparent;
}

.Profile .nav-tabs > li {
    float: left;
    margin-bottom: -1px;
    padding: 9px;
}

.Profile .nav-tabs > li > a {
    margin-right: 0;
    line-height: 1.42857143;
    border-radius: 4px 4px 0 0;
    color: #676767;
    border: none;
    cursor: pointer;
}

.Profile .nav > li > a:focus,
.nav > li > a:hover {
    text-decoration: none;
    background-color: #fff;
    border: none;
    color: #229d8c;
    margin-right: 0;
}

.Profile .BoxData {
    margin-top: 31px;
    border: 1px solid #d5d5d5;
    padding: 0 0;
    border-radius: 25px;
    outline: none;
    margin-bottom: 31px;
}

.Profile .nav > li > a > img {
    max-width: 44px;
    width: auto;
    height: 24px;
    margin-right: 5px;
}

.Profile .nav li a:hover img {
    filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg)
        brightness(95%) contrast(80%);
}

.Profile .nav-tabs > li.active > a img {
    filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg)
        brightness(95%) contrast(80%);
}

.Profile .BoxData h3 {
    /* background: #f7f7f7; */
    padding: 18px;
    margin-top: 0;
    border-top-left-radius: 27px;
    border-top-right-radius: 27px;
    color: #1a9c8c;
    font-size: 19px;
    margin-bottom: 0px;
}

.Profile .BoxData h3 span {
    float: right;
    font-size: 15px;
    cursor: pointer;
}

.RowFormInfo {
    display: -webkit-box;
}

.RowFormInfo .form-group {
    width: 50%;
    padding: 12px;
}

.ProfileSubmit {
    text-align: right;
    padding: 17px;
}

.ProfileSubmit .btn-primary {
    background-image: none !important;
    background-repeat: repeat-x;
    border-color: #229d8c;
    background: #229d8c;
    padding: 5px 42px;
    border-radius: 26px;
    outline: none;
}

.ProfileSubmit .btn-primary:hover {
    border-color: #229d8c;
    background: #fff;
    transition: 0.5s;
    color: #229d8c;
}

.table .btn-primary {
    background: none;
    border: none;
    box-shadow: none;
    background-image: none;
    text-shadow: none;
    outline: none;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
    padding: 8px 21px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
}

.Ads {
    padding-top: 28px;
}

.Profile .table {
    margin-top: 26px;
}

.Profile .table .Hold {
    color: #ff0000;
}

.BackShopping img {
    margin-bottom: 40px;
}

.Profile .table .Complet {
    color: #5ac300;
}

.Profile .table .OnDelivery {
    color: #0093ff;
}

.Profile .table a {
    color: #f00;
    border: 1px solid #ddd;
    padding: 6px 8px;
    border-radius: 20px;
    font-size: 17px;
    display: inline-flex;
    text-decoration: none;
}

.Profile .table a.BackToOrder {
    color: #229d8c;
    border: 1px solid #229d8c;
    padding: 6px 8px;
    border-radius: 20px;
    font-size: 15px;
    display: inline-flex;
    text-decoration: none;
    margin-top: -11px;
}

.AddToCart {
    margin-top: 7px;
}

.AddToCart a {
    color: #fff;
    background: #229d8c;
    border: 1px solid #229d8c;
    padding: 9px 8px;
    border-radius: 20px;
    font-size: 15px;
    display: table;
    width: 50%;
    text-decoration: none;
    text-align: center;
}

.AddToCart a:hover {
    color: #229d8c;
    background: #fff;
    transition: 0.5s;
}

.AddToCart .SoldOut {
    color: #fff;
    background: #4c4c4cd9;
    border: 1px solid #4c4c4cd9;
    padding: 9px 8px;
    border-radius: 20px;
    font-size: 15px;
    display: table;
    width: 50%;
    text-decoration: none;
    text-align: center;
    cursor: not-allowed;
}

.AddToCart .SoldOut:hover {
    color: #fff;
    background: #4c4c4cd9;
    transition: 0.5s;
}

.Profile .table .MapA {
    color: #fff;
    border: 1px solid #ddd;
    padding: 6px 8px;
    border-radius: 20px;
    font-size: 14px;
    display: inline-flex;
    background: #229d8c;
    text-decoration: none;
}

.table-responsive {
    border: 0 !important;
}

.react-responsive-modal-overlay {
    background: rgba(0, 0, 0, 0.08);
}

.Profile .table .MapA:hover {
    color: #229d8c;
    border: 1px solid #229d8c;
    background: #fff;
    transition: 0.5s;
}

.Profile .table .AddToCartFav {
    color: #fff;
    border: 1px solid #ddd;
    padding: 5px 8px;
    border-radius: 20px;
    font-size: 13px;
    display: inline-flex;
    text-decoration: none;
    margin-right: 13px;
    background: #229d8c;
    margin-top: 38px;
}

.Profile .TotalBox {
    border: 1px solid #d5d5d5;
    border-radius: 27px;
    margin-top: 6px;
    margin-bottom: 27px;
}

.Profile .TotalBox h3 {
    text-align: center;
    border-bottom: 1px solid #d5d5d5;
    padding-bottom: 13px;
    color: #1a9c8c;
    text-transform: uppercase;
    padding: 10px;
}

.Profile .TotalBox .table > tbody > tr > td {
    border-top: none;
}

.AddLocCart {
    position: absolute;
    right: 17px;
    color: #009688;
    z-index: 99999;
}

.modal-header {
    flex-direction: row-reverse;
    align-items: center;
}

.modal-header .close {
    margin: 0;
    margin-left: auto;
    padding: 0;
    font-size: 25px;
}

.modal.fade .modal-dialog {
    /* transform: translate(0,60px); */
    top: 10%;
}

.modal-footer .btn:not(:disabled):not(.disabled) {
    margin: 7px !important;
    background-color: #1a9c8c;
    background-image: none;
    color: white;
}

/* .myModalDelivery {
  top: 30%;
} */

.deliverySelect a:hover {
    color: #1aa596;
}

.kep-login-facebook {
    font-family: inherit !important;
    font-weight: 400 !important;
    -webkit-font-smoothing: antialiased;
    color: #4c69ba !important;
    cursor: pointer;
    display: inline-block;
    font-size: calc(0.27548vw + 12.71074px);
    text-decoration: none;
    text-transform: capitalize !important;
    transition: background-color 0.3s, border-color 0.3s;
    background-color: #fff !important;
    /* border: calc(0.06887vw + -0.32231px) solid #4c69ba !important; */
    /* padding: calc(0.34435vw + 5.38843px) calc(1.34435vw + 18.38843px) !important; */
    border-radius: 18px !important;
    outline: none;
    height: 100%;
    min-width: 150px;
    padding: 5px 20px !important;
}

.kep-login-facebook.metro {
    border-radius: 22px !important;
    font-size: 15px;
}

.kep-login-facebook:hover {
    color: #fff !important;
    background-color: #4c69ba !important;
}

.Profile .TotalBox .table > tbody > tr:last-child > td {
    border-top: 1px solid #d5d5d5;
}

.ButtonCost {
    margin-top: 21px;
    text-align: left;
}

.ButtonCost a:first-child {
    color: #fff !important;
    border: 1px solid #ddd;
    padding: 6px 14px;
    border-radius: 20px;
    font-size: 17px;
    display: inline-flex;
    text-decoration: none;
    background: #009688;
    margin-right: 13px;
}

.ButtonCost a:last-child {
    color: #fff !important;
    border: 1px solid #ddd;
    padding: 6px 14px;
    border-radius: 20px;
    font-size: 17px;
    display: inline-flex;
    text-decoration: none;
    background: #229d8c;
    margin-right: 13px;
    float: right;
}

.float-r {
    float: right;
}

.ButtonCost a:last-child:hover {
    background: #229d8c;
    transition: 0.5s;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
    outline: none;
    border: 4px solid #009688;
    border-radius: 7px;
}

/* -- quantity box -- */

.quantity {
    display: inline-block;
}

.quantity .input-text.qty {
    width: 44px;
    height: 31px;
    padding: 0px 6px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #607d8b5c;
    margin: 0px 0px;
}

.quantity.buttons_added {
    text-align: left;
    position: relative;
    white-space: nowrap;
    vertical-align: top;
}

.quantity.buttons_added input {
    display: inline-block;
    margin: 0;
    vertical-align: top;
    box-shadow: none;
}

.quantity.buttons_added .plus {
    padding: 0px 12px;
    height: 31px;
    background: none;
    border: 1px solid #d5d5d5;
    cursor: pointer;
    color: #d5d5d5;
    border-radius: 0 12px 12px 0;
    font-size: 20px;
}

.quantity.buttons_added .minus {
    padding: 0px 12px;
    height: 31px;
    font-size: 20px;
    background: none;
    border: 1px solid #d5d5d5;
    cursor: pointer;
    color: #d5d5d5;
    border-radius: 12px 0 0 12px;
}

.quantity.buttons_added .minus {
    border-right: 0;
}

.quantity.buttons_added .plus {
    border-left: 0;
}

.quantity input::-webkit-outer-spin-button,
.quantity input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
}

.quantity.buttons_added .minus:focus,
.quantity.buttons_added .plus:focus {
    outline: none;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover,
.nav .dropdown-toggle {
    background-color: #229d8c !important;
    border-color: #337ab7 !important;
    color: #fff !important;
    font-size: 20px;
    line-height: 1.5 !important;
}

.FavTab {
    display: -webkit-inline-box;
    padding: 14px 0;
}

.FavProductDesc {
    padding-left: 12px;
}

.FavProductDesc h2 {
    margin-top: 18px;
    color: #1a9c8c;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 6px;
}

.FavProductDesc p {
    margin: 4px 0;
    font-size: 13px;
    color: #817d7d;
    width: 100%;
    font-weight: 100;
}

.FavProductDesc strong {
    color: #a4a4a4;
    font-size: 15px;
}

.RightText h5 {
    margin-top: 4px;
}

.FilterBar {
    border: 1px solid #d0d0d0;
    padding: 18px 1px 14px 17px;
    border-radius: 32px;
    margin-bottom: 19px;
}

.FilterBar .InLineList {
    display: -webkit-inline-box;
}

.FilterBar .InLineList li {
    margin-right: 16px;
}

.FilterBar .InLineList .dropdown-menu li {
    margin-right: 0;
}

.FilterTags {
    text-align: right;
}

.FilterBar .FilterTags .FilterType {
    text-decoration: none;
    background: #a4a4a4;
    padding: 6px 21px;
    border-radius: 3px;
    color: #fff;
    line-height: 1.7;
}

.FilterBar .FilterTags .FilterType:hover,
.FilterBar .FilterTags .FilterType.active {
    background: #229d8c;
    box-shadow: 0 0 10px #229d8c;
    transition: 0.5s;
}

.FilterBar .FilterTags .btn-primary {
    background-image: none;
    background: transparent;
    background-repeat: repeat-x;
    border: 2px solid #a4a4a4;
    color: #a4a4a4;
    text-shadow: none;
    border-radius: 12px;
    outline: none;
}

.FilterBar .FilterTags .btn-primary {
    background-image: none;
    background: transparent;
    background-repeat: repeat-x;
    border: 2px solid #a4a4a4;
    color: #a4a4a4;
    text-shadow: none;
    border-radius: 12px;
    margin-top: -8px;
    outline: none;
}

.FilterBar .FilterTags .open > .dropdown-toggle.btn-primary.focus,
.FilterBar .FilterTags .open > .dropdown-toggle.btn-primary:focus,
.FilterBar .FilterTags .open > .dropdown-toggle.btn-primary:hover {
    color: #229d8c;
    background: none;
    border-color: none;
    outline: none;
    border-color: #229d8c;
}

.StoreCategories {
    background: #1a9c8c;
    padding: 11px;
    border-radius: 30px;
    text-align: center;
    color: #fff;
    margin-bottom: 22px;
}

.HomeCat .StoreCategories {
    margin-top: 26px;
}

.HomeCat .StoreCategories a {
    color: #fff;
    font-size: 15px !important;
    padding-left: 4px;
}

.HomeCat .StoreCategories a:hover {
    color: #ffffffcf;
    font-size: 15px !important;
}

.HomeCat .StoreCategories .fa {
    position: absolute;
    left: 10px;
    margin-top: 5px;
}

.HomeCat .StoreCategories a span {
    position: absolute;
    right: 17px;
    margin-top: 3px;
}

.StoreCategories ol {
    list-style: circle;
    padding: 0 0 0px 21px;
}

.SliderPrice {
    display: -webkit-inline-box;
    direction: ltr;
}

.SliderPrice h6 {
    padding: 0 16px 0 16px;
    text-decoration: line-through;
    font-size: 15px;
    margin-top: 6px;
}

.StoreCategories h1 {
    border-bottom: 1px solid #504b4b36;
    padding-bottom: 11px;
    margin-top: 0px;
    font-size: 24px;
    text-transform: uppercase;
}

.StoreCategories ul {
    margin-left: 16px;
    font-size: 18px;
}

.StoreCategories ul li {
    margin-bottom: 9px;
}

.StoreCategories ul li a {
    color: #fff;
}

.ProductCol {
    border: 1px solid #e3e3e3;
    border-radius: 27px;
    padding: 17px 0;
    margin-bottom: 20px;
}

.ImgProductCol {
    border: 1px solid #e3e3e3;
    border-radius: 27px;
    padding: 9px;
}

.ImgProductCol img {
    height: 265px;
    width: 100%;
}

.ProductCol h3 {
    color: #1a9c8c;
}

.ProductCol p {
    color: #a4a4a4;
}

.ProductCol strong {
    color: #a4a4a4;
    font-size: 18px;
}

.ProductCol span {
    background: #ffba00;
    padding: 4px 13px;
    border-radius: 26px;
    color: #fff;
    text-align: center;
    position: absolute;
    right: 0;
    font-size: 15px;
}

.ProductCol ul li {
    margin-bottom: 23px;
}

.ProductCol ul li:first-child a {
    border: 1px solid #1a9c8c;
    padding: 6px 12px;
    border-radius: 19px;
    background: #1a9c8c;
    color: #fff;
    text-decoration: none;
}

.ProductCol ul li:first-child a:hover {
    box-shadow: 0 3px 9px #229d8c;
    transform: scale(1.8);
}

.ProductCol ul li:last-child a {
    border: 1px solid #ff0000;
    padding: 6px 12px;
    border-radius: 19px;
    background: #ff0000;
    color: #fff;
    text-decoration: none;
}

.ProductCol ul li:last-child a:hover {
    box-shadow: 0 3px 9px #ff0000;
    transform: skew(44deg, 66deg);
}

.ProductCol ul li a img {
    filter: invert(0%) sepia(30%) saturate(124%) hue-rotate(119deg)
        brightness(100%) contrast(155%);
    margin-top: -3px;
}

.ThreeProducts a {
    text-decoration: none;
}

.ThreeProducts .ImageWrapper {
    text-align: center;
    border: 1px solid #d0cfcf;
    border-radius: 30px;
    padding: 15px;
    margin-left: auto;
    margin-right: auto;
    margin: 19px;
}

.ThreeProducts .thumbnail {
    display: block;
    padding: 0;
    margin-bottom: 20px;
    line-height: 1.42857143;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 30px;
    text-align: center;
}

.ThreeProducts .thumbnail h3 {
    color: #817d7d;
}

.ThreeProducts .thumbnail span {
    color: #a4a4a4;
    font-size: 21px;
}

.ThreeProducts .ViewProduct {
    margin-top: 31px;
    display: -webkit-box;
    padding: 0;
}

.ThreeProducts .ViewProduct li {
    padding: 17px;
    width: 50%;
    text-align: center;
    border-bottom-left-radius: 28px;
}

.FiltersProducts {
    border: 1px solid #e3e3e3;
    text-align: center;
    margin-bottom: 22px;
    border-radius: 32px;
}

.FiltersProducts h3 {
    color: #1a9c8c;
    padding-bottom: 15px;
    border-bottom: 1px solid #e3e3e3;
}

.FiltersProducts .FilterItem {
    padding-left: 30px;
}

.FiltersProducts .FilterItem h4 {
    color: #676767;
    text-align: left;
    font-size: 20px;
    margin-top: 38px;
}

.PriceSlider {
    margin-top: 39px;
    padding: 0 44px 0 6px;
    margin-bottom: 53px;
}

.input-range__label--value {
    position: absolute;
    top: -2.8rem;
}

.input-range__label {
    color: #aaaaaa;
    font-family: inherit;
    font-size: 13px;
}

.input-range__label--min,
.input-range__label--max {
    display: none;
}

.input-range__track--active {
    background: #1a9c8c;
}

.input-range__slider {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #1a9c8c;
    border: 1px solid #1a9c8c;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 2rem;
    margin-left: -0.5rem;
    margin-top: -12px;
    outline: none;
    position: absolute;
    top: 50%;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    width: 2rem;
}

/* Customize the label (the container) */

.containerColor {
    display: -webkit-inline-box;
    position: relative;
    padding-left: 34px;
    margin-bottom: 34px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */

.containerColor input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 35px;
    width: 35px;
    background-color: #eee;
    border-radius: 20px;
    border: 2px solid #eee;
}

/* On mouse-over, add a grey background color */

.containerColor:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

.containerColor input:checked ~ .checkmark {
    background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */

.containerColor input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */

.containerColor .checkmark:after {
    left: 12px;
    top: 7px;
    width: 7px;
    height: 14px;
    border: solid #1a9c8c;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.ColorWrapper {
    margin-top: 14px;
    padding: 0;
    margin-bottom: 53px;
    text-align: left;
}

.select-size {
    text-align: left;
    margin-bottom: 47px;
}

.select-size input {
    display: none;
}

.select-size label {
    display: inline-block;
    width: 40px;
    height: 40px;
    text-align: center;
    border: 2px solid #ddd;
    line-height: 36px;
    cursor: pointer;
    margin: 3px;
    border-radius: 6px;
}

#small:checked ~ label[for="small"],
#medium:checked ~ label[for="medium"],
#large:checked ~ label[for="large"],
#x-large:checked ~ label[for="x-large"],
#xx-large:checked ~ label[for="xx-large"] {
    background: #1a9c8cbf;
    color: #fff;
    border: 2px solid #1a9c8c;
}

.ImageOfferFilter {
    margin-bottom: 17px;
}

.DetailsBox {
    border: 1px solid #d5d5d5;
    margin: 6px;
    border-radius: 14px;
    margin-bottom: 36px;
}

.DetailsBox h2 {
    color: #229d8c;
    font-weight: 600;
    font-size: 21px;
    text-transform: uppercase;
    margin-top: 37px;
}

.qty-container {
    margin-top: 2rem;
    max-width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "RegularBodyRoman";
}

.qty-container span {
    display: inline-block;
    color: #229d8c;
    user-select: none;
}

.qty-container .qty-container__sign {
    font-size: 27px;
    cursor: pointer;
    border: 1px solid #d5d5d5;
    height: 30px;
    width: 20px;
    /* padding: 0 10px; */
    border-radius: 0 12px 12px 0;
}

.qty-container .dec {
    font-size: 30px !important;
    border-radius: 12px 0 0 12px;
}
.qty-container .qty-container__box {
    font-size: 20px;
    border-top: 1px solid #d5d5d5;
    width: 100%;
    padding: 8px 0;
    text-align: center;
}
.DetailsBox .DescAndQty {
    display: inline-flex;
    margin: 17px 0;
}

.DescAndQty p {
    color: #817d7d;
}

.ImageDetailsWrapper {
    text-align: center;
    padding: 13px;
    border: 1px solid #d5d5d5;
    margin: 23px 0;
    border-radius: 14px;
}

.ImageDetailsWrapper img {
    border-radius: 18px;
    width: 100%;
    height: 100px;
    padding: 4px;
    object-fit: cover;
}

.NoReviews {
    margin-left: 17px;
    font-size: 17px;
    color: #1a9c8c;
}

.OptionImageSliderDet {
    border-radius: 18px;
    width: 100%;
    height: 458px !important;
    padding: 4px;
}

.DetailsBox h4 {
    color: #229d8c;
    font-size: 22px;
}

.DetailsBox h6 {
    background: #ffba00;
    padding: 4px 13px;
    border-radius: 26px;
    color: #fff;
    text-align: center;
    position: absolute;
    right: 0;
    font-size: 15px;
    margin-top: 9px;
}

.CommentsForm {
    padding: 17px;
}

.CommentsForm .SendDiss {
    background: #1a9c8c;
    border: 1px solid #1a9c8c;
    color: #fff;
    border-radius: 35px;
}

.CommentsForm .SendDiss:hover {
    background: #fff;
    color: #1a9c8c;
    transition: 0.5s;
}

.PriceAndOff {
    display: grid;
}

.ShareRow ul {
    display: inline-flex;
}

.ShareRow ul li {
    margin-left: 7px;
}

.TabsDetails .nav-tabs {
    border: 1px solid #ddd;
    border-bottom: none;
    width: 300px;
    text-align: center;
    border-top-right-radius: 23px;
    border-top-left-radius: 23px;
}

.TabsDetails .nav-tabs li {
    width: 50%;
}

.TabsDetails .nav-tabs > li > a {
    margin-right: 0;
    line-height: 1.42857143;
    border: none;
    border-radius: 0;
    padding: 16px 40px;
    color: #817d7d;
}

.TabsDetails #Desc h1 {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 6px;
    color: #229d8c;
}

.TabsDetails #Desc p {
    padding: 0;
}

.TabsDetails .nav-tabs > li.active > a,
.TabsDetails .nav-tabs > li.active > a:focus,
.TabsDetails .nav-tabs > li.active > a:hover {
    color: #fff;
    cursor: default;
    background-color: #1a9c8c;
    border: none;
    border-bottom-color: transparent;
    padding: 16px 40px;
    border-top-left-radius: 17px;
}

.TabsDetails .nav-tabs > li:last-child.active > a {
    color: #fff;
    cursor: default;
    background-color: #1a9c8c;
    border: none;
    border-bottom-color: transparent;
    padding: 16px 40px;
    border-top-left-radius: 0;
    border-top-right-radius: 17px;
}

.TabsDetails .tab-content {
    border: 1px solid #ddd;
    padding: 9px;
    margin-bottom: 20px;
    border-radius: 0 17px 17px 17px;
}

.TabsDetails p {
    color: #817d7d;
    line-height: 1.7;
    padding: 9px;
}

.TabsDetails .nav > li > a:focus,
.nav > li > a:hover {
    text-decoration: none;
    background-color: transparent;
    border: none;
    color: #229d8c;
    margin-right: 0;
}

.DetailsMedia {
    display: flex;
    margin-top: 15px !important;
}

.DetailsMedia img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 30px;
}

.DetailsMedia .media-body {
    border: 1px solid #d5d5d5;
    padding: 15px;
    border-radius: 16px;
}

.DetailsMedia .media-body:before {
    content: "";
    position: absolute;
    left: 96px;
    top: auto;
    width: 0;
    height: 0;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 16px solid #d5d5d587;
    clear: both;
    transform: rotate(91deg);
    margin-top: 13px;
}

.DetailsMedia .media-body:after {
    content: "";
    position: absolute;
    left: 97px;
    top: 117px;
    width: 0;
    height: 0;
    border-left: 17px solid transparent;
    border-right: 17px solid transparent;
    border-top: 17px solid #fff;
    clear: both;
    transform: rotate(92deg);
    display: none;
}

.DetailsMedia h5 {
    color: #817d7d;
    font-size: 15px;
}

.DetailsMedia span {
    color: #d4d2d2;
}

.DetailsMedia p {
    color: #817d7d;
    line-height: 1.7;
    padding: 9px 0;
}

.ProductBox h5 {
    color: #1aa596;
    display: -webkit-inline-box;
}

.ProductBox h5 h6 {
    margin-top: 0;
    padding: 0 5px;
}

.NEWARRIVED a {
    text-decoration: none;
}

.map {
    height: 400px !important;
    position: relative !important;
}

.Profile .table a.PriceOption {
    color: #fff;
    border: 1px solid #ddd;
    padding: 6px 8px;
    border-radius: 20px;
    font-size: 14px;
    /* display: inline-flex; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin-top: 12px;
    text-align: center;
}

.Profile .table a.PriceOption:hover {
    color: #1aa596;
    border: 1px solid #1aa596;
    transition: 0.5s;
}

.Profile .FavFooter a {
    font-size: 14px;
}

.image-gallery-thumbnail {
    border-radius: 19px;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 61px;
    width: 60px;
}

.image-gallery-icon:hover {
    color: #1aa596;
}

.DetailsBox .ProductsBox ul {
    text-align: left;
}

.DetailsBox .CartModal ul li a {
    margin-top: 16px;
}

.labelCard {
    position: absolute;
    padding: 7px;
    background: linear-gradient(
        98deg,
        rgba(22, 116, 104, 1) 20%,
        rgba(26, 155, 139, 0.9808298319327731) 81%
    );
    color: #fff;
    border-radius: 7px 0 7px;
    left: 16px;
    right: auto;
    font-family: "MyriadproBold";
}

.priceProduct {
    display: inline-grid;
    padding-top: 4px;
}
.priceProduct span.oldPricespan {
    font-size: 11px !important;
    text-decoration: line-through;
}
.oldPricespan-noValue {
    height: 13px;
    background-color: white;
}

.favourites-tabal-title {
    /* background: #f7f7f7; */
    /* padding: 18px; */
    margin-top: 0;
    border-top-left-radius: 27px;
    border-top-right-radius: 27px;
    color: #1a9c8c;
    font-size: 19px;
}

.labelCardDetails {
    color: #198d7e;
}

.btn:not(:disabled):not(.disabled) {
    font-size: 14px;
}

.ContactPage {
    text-align: left;
}

.ContactPage .ContactBtn {
    color: #fff;
    background: #1aa596;
    border: 1px solid #1aa596;
    padding: 8px 25px;
    border-radius: 20px;
    font-size: 14px;
    display: inline-flex;
    text-decoration: none;
    margin-top: 12px;
    text-align: center;
    margin-left: 11px;
}

.ContactPage .ContactBtn:hover {
    color: #1aa596;
    background: #fff;
    transition: 0.5s;
}

.ContactPhone {
    background: #1aa596;
    border-radius: 32px;
    margin-top: 23px;
    padding-bottom: 9px;
    margin-bottom: 62px;
}

.ContactPhone h1,
.ContactPhone a {
    color: #fff;
}

.SectionSearch {
    min-height: 500px !important;
    font-family: "RegularBodyRoman";
}

.SectionSearch h1 {
    color: #229d8c;
    text-transform: capitalize;
    margin: 20px 0;
}

.About .content {
    text-align: left;
}

.About .content b,
.SuccessOrder {
    color: #229d8c;
}

.Profile .TotalBox .table {
    margin-top: 0;
}

.ShowOpDenark {
    background: #229d8c;
    padding: 8px;
    margin: 7px !important;
    color: #fff;
    border-radius: 4px;
    line-height: 1.5;
}

.ShowOpDenark:hover {
    background: #229d8cc7;
    color: #fff;
}

.modal-backdrop {
    background-color: rgb(0 0 0 / 0.6) !important;
}

.iiz__img {
    height: 100%;
    max-height: 500px;
}

.terms-title {
    text-align: center;
    margin-bottom: 20px !important;
    margin-top: 40px !important;
    color: #1a9c8c !important;
}

/* .copyLinkLi{
  background-color: #198d7e;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 50%;
} */

.copyLinkLi img {
    width: 32px;
}

/****** Media Queries **********/

@media (min-width: 991.99px) and (max-width: 1199.98px) {
    .CartButtons .LoginUL .LiCircle {
        margin-left: 11px;
        padding: 8px;
        margin-right: 7px;
    }
    .AccountLogin .AccountButton {
        font-size: 12px;
    }
    .DealBox {
        padding: 6px 3px;
    }
    .carousel .slide {
        padding: 60px 27px;
    }
    .popularProducts ul a {
        padding: 7px 14px;
    }
    .Footer img {
        width: 84%;
    }
    .FooterSocialList li {
        width: 40px;
        height: 40px;
        padding-top: 6px;
        margin-right: 2px;
    }
    .react-slider__imgZoom {
        width: 100% !important;
    }
}

@media (min-width: 767.99px) and (max-width: 991.98px) {
    .SearchAndLogo .Logo img {
        height: 41px;
    }
    .CartButtons .LoginUL .LiCircle {
        margin-left: 11px;
        padding: 8px;
        margin-right: 7px;
    }
    .AccountLogin .AccountButton {
        font-size: 12px;
    }
    .SearchHeader .dropdown-toggle {
        font-size: 14px;
        padding: 2px 4px;
    }
    .DealBox {
        padding: 6px 3px;
    }
    .ProductNameInBox h3 {
        width: 117px;
    }
    .carousel .slide {
        padding: 41px 31px;
    }
    .carousel .slide h2 {
        font-size: 24px;
    }
    .NEWARRIVED h1 {
        font-size: 32px;
    }
    .HomeSellProducts strong {
        font-size: 48px;
    }
    .HomeSellProducts h1 {
        font-size: 36px;
        padding-top: 40px;
        margin-bottom: 0;
    }
    .react-slider__imgZoom {
        width: 100% !important;
    }
    .popularProducts ul a {
        padding: 7px 14px;
    }
    .Footer img {
        width: 84%;
    }
    .FooterSocialList li {
        width: 40px;
        height: 40px;
        padding-top: 6px;
        margin-right: 2px;
    }
    .Footer .SubFooter img {
        margin-left: 13px;
    }
    .SubFooter h5 {
        font-size: 10px;
    }
    .Form .form-control {
        width: 50%;
    }
    .phone-input__content,
    .custom-select {
        width: 50%;
    }
    .carousel .thumbs {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    body {
        margin-top: 100px;
    }
    .side-collapse-container {
        width: 100%;
        position: relative;
        left: 0;
        transition: left 0.4s;
    }
    .side-collapse-container.out {
        left: 200px;
    }
    .side-collapse {
        top: 50px;
        bottom: 0;
        left: 0;
        width: 200px;
        position: fixed;
        overflow: hidden;
        transition: width 0.4s;
    }
    .TabsDetails {
        margin-top: 41px;
    }
    .DetailsMedia .media-body:before {
        left: 105px;
    }
    .react-slider__imgZoom {
        display: none;
    }
    .RelatedH1 {
        font-size: 23px;
    }
    input[type="file"] {
        display: block;
        font-size: 8px;
    }
    .side-collapse.in {
        width: 0;
    }
    .no_pad {
        padding: 0 8px !important;
    }
    .HomeCat .StoreCategories .fa {
        left: 22px;
    }
    .modal {
        z-index: 9999999999999999;
    }
    .DetailsBox {
        margin: 0px;
    }
    .row {
        margin: auto 0;
    }
    /* .navbar-inverse {
    background-image: linear-gradient(to bottom, #229d8c 0, #229d8c 100%);
    background-repeat: repeat-x;
    border-radius: 4px;
    left: 0;
    z-index: 9999999999999999;
  }
  .navbar-inverse .navbar-collapse,
  .navbar-inverse .navbar-form {
    border-color: #229d8c;
  }
  .DropDownLists .HeaderList {
    margin-top: 6px;
    margin-left: 16px;
  }
  .navbar-inverse .navbar-toggle:focus,
  .navbar-inverse .navbar-toggle:hover {
    background-color: #a2a3a3;
    border-color: #a2a3a3;
  }
  .navbar-inverse .navbar-toggle {
    border-color: #fff;
  }
  .Header .nav > li > a {
    padding: 11px 33px;
  }
  .Logo {
    text-align: center;
    margin-bottom: 9px;
    margin-top: 66px;
  } */
    .ProductNameInBox {
        width: 81%;
    }
    .DealBox {
        padding: 6px 4px;
    }
    .carousel .slide img {
        width: 91%;
        margin-top: 42px;
    }
    .carousel .slide {
        padding: 26px 10px;
    }
    .HomeSellProducts strong {
        font-size: 48px;
    }
    .HomeSellProducts h1 {
        font-size: 36px;
        padding-top: 40px;
        margin-bottom: 0;
    }
    .SooqSpac ul li img {
        height: 27px;
    }
    .SooqSpac ul li h2 {
        font-size: 11px;
    }
    .Header {
        /* position: fixed;
    
    
    */
        width: 100%;
        top: 0;
        z-index: 999999999;
    }
    .FooterLOgo {
        text-align: center;
    }
    .Footer {
        padding: 8px 16px;
    }
    .SubFooter {
        padding-top: 7px;
        text-align: center;
    }
    .Ads {
        margin-bottom: 40px;
    }
    .Form .form-control {
        width: 80%;
    }
    .Profile .nav li a img {
        width: 15px;
        object-fit: scale-down;
    }
    .NEWARRIVED .ViewProduct a,
    .POPULAR .ViewProduct a {
        padding: 7px 9px;
        font-size: 9px;
    }
    .POPULAR h1 {
        font-size: 23px;
    }
    .DropDownLists .HeaderList {
        display: inline-flex;
    }
    .FilterTags {
        text-align: center;
    }
    .CartModal .modal-dialog {
        width: 100%;
        margin: 22% auto;
    }
    .EmptyCart img {
        width: 100%;
    }
    .modal {
        background: #5151515c;
    }
    .CartModal ul li a {
        padding: 5px 0px;
        margin-top: 4px;
        width: 80px;
        margin: 0;
    }
    .carousel .thumbs {
        display: none;
    }
    .FilterBar .InLineList li {
        margin-right: 16px;
        display: -webkit-inline-box;
        margin-bottom: 24px;
    }
    .FilterBar .InLineList {
        display: -webkit-inline-box;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    .FilterBar .InLineList li:last-child {
        margin-bottom: 0;
    }
    .FilterBar .InLineList .dropdown-menu li {
        margin-right: 0;
        margin-bottom: 0;
    }
    .Footer h4 {
        font-size: 18px;
    }
    /* .Header .header-cart .cartIcon span {
        top: 22% !important;
    } */
    .phone-input__content,
    .custom-select {
        width: 80%;
    }
}

@media (min-width: 540px) and (max-width: 772px) {
    .DealBox {
        padding: 6px 22px;
    }
    .carousel .slide img {
        width: 50%;
        margin-top: 42px;
    }
    /* body{
    margin-top: 140px;
  } */
}

@media screen and (max-width: 576px) {
    .LoginForm .row .content table {
        width: 400px !important;
    }
    .modal.fade .modal-dialog {
        top: 20%;
    }
    .table > caption + thead > tr:first-child > td,
    .table > caption + thead > tr:first-child > th,
    .table > colgroup + thead > tr:first-child > td,
    .table > colgroup + thead > tr:first-child > th,
    .table > thead:first-child > tr:first-child > td,
    .table > thead:first-child > tr:first-child > th {
        font-size: 16px !important;
    }
    /* body{
  margin-top: 143px;
} */
    .Form .btn-primary {
        width: 55%;
        font-size: 13px !important;
    }
}
@media screen and (max-width: 425px) {
    .LoginForm .row .content table {
        width: 300px !important;
    }
    .LoginForm .row .content table span {
        font-size: 12px !important;
    }
}

.modal-body-Verification {
    text-align: right;
    /* overflow-y: hidden !important; */
}
.modal-body-Verification::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
}

.modal-body-Verification ::-webkit-scrollbar {
    width: 0 !important;
}
