/* @font-face {
    font-family: "MyriadproLight";
    src: url(./fonts/fontsNew/FrutigerLTArabic55Roman.ttf);  }
   */
/* @font-face {
    font-family: "MyriadproRegular";
    src: url(./fonts/Al-Jazeera-Arabic-Regular.ttf);
  } */
/* .body{
    font-family: "MyriadproRegular" ;
    } */

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.container-fluid {
    padding-right: 30px !important;
    padding-left: 30px !important;
}
.no-padd {
    padding: 0 !important;
}

/* header  */
.Header {
    background-color: #1a9c8c;
    padding: 10px 0px 0 0;
    font-family: "RegularBodyRoman" !important;
}
.Header
    .input-group
    > .input-group-append:last-child
    > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
    > .input-group-append:last-child
    > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
    background-color: #f3f3f3;
    padding: 0 10px !important;
    border: 0;
    margin-bottom: 1px;
    color: #707070;
    font-size: 14px;
}
.search-div {
    border: 2px solid #127c6f;
    border-radius: 5px;
    width: 100%;
}

@media (max-width: 1240px) {
    .search-div {
        width: 85%;
    }
}

@media (max-width: 767px) {
    .search-web {
        display: none !important;
    }
}

.search-div .mb-3,
.search-div .my-3 {
    margin: 0 !important;
}
.Header .padd-bot {
    padding-bottom: 10px;
}
.Header .input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
    > .input-group-prepend:first-child
    > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    background: rgb(26, 156, 140);
    background: linear-gradient(
        90deg,
        rgba(26, 156, 140, 1) 2%,
        rgba(16, 106, 95, 1) 88%
    );
    border: 0 !important;
}
.Header .btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
    border: 0 !important;
}
.Header .input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control,
.input-group > .form-control-plaintext {
    margin-bottom: 1px !important;
}
.Header .form-control:focus {
    box-shadow: none !important;
    border: 0;
}

.Header .formSearchSelect {
    height: auto;
}
.Header .formSearchSelect .form-group {
    height: 100%;
    margin-bottom: 0 !important;
}

.Header .formSearchSelect .form-group select {
    height: 100%;
    font-size: 13px;
    border: 0;
}
.Header .formSearchSelect .form-group select:focus,
.Header .formSearchSelect .form-group select:hover,
.Header .formSearchSelect .form-group select:active {
    outline: none !important;
    box-shadow: none !important;
    border: 0px;
}
.Header .first-back-btn {
    display: flex;
    align-items: center;
}
.Header .first-back-btn span {
    margin: 0;
}
.Header .formSearchSelect .form-group select option {
    font-size: 12px;
    /* background-color: red; */
    color: #198d7e;
}
.Header .formSearchSelect .form-group select option:hover {
    background-color: #198d7e !important;
    color: red;
    cursor: pointer;
}
.form-group-phone-countryCode {
    display: flex;
    justify-content: center;
}
.input-group-text-phone-countryCode {
    width: 5%;
    border-top-left-radius: 13px;
    border-bottom-left-radius: 13px;
}
.form-control__phone-countryCode {
    border-bottom-left-radius: 0% !important;
    border-top-left-radius: 0% !important;
    width: 28% !important;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
    /* background-color: #1a9c8c !important; */
    box-shadow: none !important ;
    height: 100%;
}
.Header .dropdown-toggle::after {
    color: #707070;
}
.language-dropdown {
    margin: 0px 15px;
}
.language-dropdown .dropdown-toggle {
    background-color: transparent;
    border: 0;
    display: flex;
    justify-content: space-around !important;
    align-items: center;
    width: 100%;
    padding: 0;
}
.language-dropdown .dropdown-toggle::after {
    color: white;
    margin-left: 5px;
    /* appearance: auto !important; */
    /* background-color: white;
    /* ///// 
      background: #fff url("./chevron-down.png") no-repeat !important;
      background-position-y: center !important; */
}
.language-dropdown .dropdown-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.language-dropdown .dropdown-toggle img {
    width: 30px;
    margin-right: 5px;

    object-fit: scale-down;
}
.Dropdown-Menu-Currency {
    inset: 0px auto auto -22px !important;
}
.language-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
}
.Header .btn-primary {
    box-shadow: none;
    background-image: none !important;
}
.Header .btn-primary:not(:disabled):not(.disabled).active,
/* .btn-primary:not(:disabled):not(.disabled):active, */
.show > .btn-primary.dropdown-toggle,
.Header .btn-primary:hover,
.Header .btn-primary.focus,
.btn-primary:focus {
    background-color: transparent !important;
    border: 0 !important;
    outline: none !important;
    box-shadow: none !important;
}
.Header .cartLangAcc {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.Header .user-header {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin: 0px 15px;
}
.Header .user-header img {
    margin-right: 15px;
    width: 22px;
    height: 25px;
}
.Header .user-signin .btn-primary {
    font-size: 15px;
    background-color: transparent !important;
    border: 0px !important;
    display: flex;
    align-items: center;
    padding: 0;
}
.Header .user-signin .btn-primary h6 {
    margin: 0;
    /* font-size: 12px; */
    font-size: 14px;
}
.Header .user-signin .dropdown-toggle::after {
    color: white !important;
}
.Header .user-signin a {
    color: white;
    font-size: 12px;
}
.Header .user-signin a:hover {
    color: silver;
}
.Header .user-signin p {
    margin: 0;
    font-size: 14px;
    text-transform: capitalize;
}
.Header .user-signin .nameUser {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: start;
    direction: initial;
}
/* .Header .user-signin .btn-primary h6{
    font-family: 'MyriadproBold';
  
  } */
.Header .header-cart {
    display: flex;
    align-items: center;
    float: right;
    margin: 0px 0 0 15px;
    cursor: pointer;
}
.Header .header-cart .cartIcon {
    position: relative;
    /* margin-right: 10px;
     */
}

.Header .header-cart .cartIcon span {
    position: absolute;
    top: 16.5% !important;
    width: 12px;
    text-align: center;
    /* top: 11%; */
    left: auto;
    bottom: auto;
    right: 28%;
    font-size: 14px;
    color: #ffe200;
    min-width: 7px;
}
.Header .header-cart p {
    margin: 0;
    /* font-family:'MyriadproBold'; */
    color: white;
    font-size: 20px;
}
.logo {
    display: flex;
    width: 100%;
    height: 100%;
}
.logo img {
    cursor: pointer;
}
.Header .btn-outline-secondary.focus,
.btn-outline-secondary:focus {
    box-shadow: none !important;
}

.search-mobile {
    display: none !important;
}
.search-mobile .search-div {
    width: 100% !important;
}
.Row-search-mobile {
    display: flex;
    justify-content: center;
}
.Header .dropdown-menu {
    min-width: 8rem;
}
.Header .dropdown-menu .dropdown-item {
    font-size: 13px;
    color: #5c5c5c;
    text-transform: capitalize;
}
.Header .dropdown-menu .dropdown-item:hover {
    color: #198d7e;
}
.Header .input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
    border: 0 !important;
    min-height: 29px; /* from new update  */
    padding: 17px;
    font-size: 13px;
}
.bottom-header {
    background-color: #198d7e;
}
.bottom-header-handmade {
    background-color: #fff;
    box-shadow: 0 0 3px 0 inset #e8e8e8;
}
.bottom-header-content-handmade,
.bottom-header-content {
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.bottom-header-content-handmade h5 {
    color: #1a9c8c;
    margin: 0;
    /* font-family: 'MyriadproBold'; */
    padding-left: 5px;
    padding-top: 3px;
    font-size: 20px;
}
.bottom-header-handmade span {
    color: #1a9c8c;
    float: right;
    font-size: 14px;
    cursor: pointer;
}
.bottom-header-handmade span:hover {
    color: #5c5c5c;
}
.justify-content-space-between {
    justify-content: space-between;
    align-items: center;
}
.bottom-header-content-handmade ul {
    margin: 0;
}
.bottom-header-content ul {
    margin: 0;
    margin-bottom: 3px;
    /* padding-left: 20px; */
}
.bottom-header-content-handmade li {
    display: -webkit-inline-box;
    margin: 0px 10px;
}
.bottom-header-content ul li {
    display: inline;
    margin: 0px 10px;
    color: white;
}
.bottom-header-content .sidebar-body ul li {
    display: flex;
}
.bottom-header-content-handmade ul li a {
    text-decoration: none;
    font-size: 14px;
    color: #5c5c5c;
    text-transform: capitalize;
    /* font-family: "RegularBodyRoman"; */
}
.bottom-header-content-handmade ul li a:hover {
    color: #127c6f;
}
.bottom-header-content ul li a {
    color: white;
    text-decoration: none;
    font-size: 14px;
}
.toggle-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    /* font-family: "RegularBodyRoman" !important; */
}
.toggle-header button {
    color: white;
    border: 0;
    background-color: transparent;
}
.toggle-header button:focus,
.toggle-header button:active {
    outline: none !important;
}
.toggle-header span {
    font-size: 14px;
    margin-left: 5px;
    /* margin-top: 5px; */
    margin-right: 5px;
    cursor: pointer;
}
.toggle-header #react-sidebar .sidebar-header {
    color: white;
    background-color: #1a9c8c;
}
.toggle-header .sidebar-body {
    background-color: #127c6f;
    height: 100%;
    padding-top: 10px;
}
.toggle-header .sidebar-body span {
    color: white;
    text-transform: capitalize;
    margin-bottom: 2px;
}
.toggle-header .logo {
    background-color: #1a9c8c;
    padding: 10px 0;
}
.toggle-header .logo img {
    width: 100%;
    object-fit: scale-down;
    cursor: pointer;
}
.toggle-header #react-sidebar .sidebar-main-content {
    height: 100%;
}
.toggle-header .sidebar-main-content ul li {
    transition: ease-in-out 0.3s;
}
.toggle-header .sidebar-main-content ul li:hover {
    background-color: #1a9c8c;
    margin: 0;
    transition: ease-in-out 0.3s;
    padding-left: 20px;
}
.toggle-header .first-back-btn {
    background-color: #1a9c8c;
}
.toggle-header svg {
    filter: brightness(4.5);
    fill: white;
}
.toggle-header .section-heading .fa {
    color: white;
    font-size: 20px;
}
.toggle-header .first-back-btn svg {
    margin-right: 10px;
    background-image: url("../src/left-arrow.svg");
    width: 13px;
    height: 13px;
    background-size: contain;
}
.toggle-header .first-back-btn svg g {
    display: none;
}
.toggle-header #react-sidebar .sidebar-main.show {
    box-shadow: none;
}

/* header end  */

/* home  */
.landing-page img {
    cursor: pointer;
    width: 100%;
}
.landing-page-img {
    cursor: pointer;
    width: 100%;
    height: 500px;
    object-fit: fill;
}
.title-section {
    padding: 40px 0;
}
.title-section h4 {
    color: #1a9c8c;
    text-align: center;
    font-style: italic;
    font-size: 24px;
    font-size: 30px;
}
.hr-section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.hr-section div {
    width: 100%;
    height: 2px;
    background: #cacaca;
    margin: auto;
}
.collections {
    padding: 40px 0;
}
.collection-item {
    position: relative;
    display: inline-block;
    box-shadow: 0 0 4px 0px silver;
    border-radius: 5px;
    background-color: #c0c0c01a;
    font-style: italic;
    width: 100%;
}

.collection-item img {
    width: 100%;
    border-radius: 5px;
    object-fit: scale-down;
}
.collection-item p {
    background-image: linear-gradient(transparent, #1a9c8c);
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    color: white;
    width: 100%;
    align-items: flex-end;
    padding-bottom: 35px;
    /* font-family: 'MyriadproBold'; */
    font-size: 22px;
    width: 100%;
    margin: 0;
    border-radius: 5px;
    height: 50%;
    text-transform: capitalize;
}
.shop-category {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;

    display: block;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    /* border: 1px solid gray; */
    margin-bottom: 30px;
    box-shadow: 0 0 4px 0px silver;
    position: relative;
    transition: ease-in-out 3s;
    font-style: italic;
    height: 380px;
}

.shop-category:hover {
    text-decoration: none;
    transition: ease-in-out 3s;
}
.shop-category .shopCategotyTitle {
    position: absolute;
    bottom: 0;
    width: 100%;
}
.shop-category:hover .shop-category-details {
    display: flex;
    transition: ease-in-out 3s;
    font-family: "MyriadproBold" !important;
}
.shop-category .shopCategotyTitle p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.shop-category img {
    /* width: 100%;
    max-height: 215px;
    object-fit: scale-down; */

    width: 100%;
    height: 324px;
    object-fit: cover;
    margin-bottom: 10px;
}
.shop-category p {
    color: #1a9c8c;
    font-size: 25px;
    /* font-family: "MyriadproBold"; */
    text-transform: capitalize;
}
.shop-category-details {
    background-color: #1a9c8cc7;
    position: absolute;
    bottom: 0;
    display: none;
    flex-direction: column;
    color: white;
    width: 100%;
    border-radius: 5px;
    height: 100%;
    padding: 20px;
    justify-content: space-around;
    align-items: center;
    transition: ease-in-out 3s;
}
.shop-category-details h5 {
    /* font-family: 'MyriadproBold'; */
    font-size: 30px;
    margin-bottom: 0;
    text-transform: capitalize;
    margin-top: 30px;
}
.shop-category-details:active,
.shop-category-details:hover {
    text-decoration: none;
    transition: ease-in-out 3s;
}
.shop-category-details span {
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    font-family: "RegularBodyRoman" !important;
    font-style: initial;
}
.shop-category-details p {
    color: white;
    font-size: 25px;
}

.joinUs {
    background: url("./Group0650.png") no-repeat;
    background-size: cover;
    background-position: center;
    height: 450px;
    text-align: center;
    margin: 50px 0;
}
.joinUs-bg-shadow {
    width: 100%;
    height: 100%;
    padding: 50px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.joinUs h4 {
    font-size: 35px;
    /* font-family: "MyriadproBold"; */
    color: white;
    font-style: italic;
}
.joinUs p {
    font-size: 20px;
    color: white;
    font-style: italic;
}
.joinUs a {
    padding: 10px 35px;
    background-color: #1a9c8c;
    color: white;
    text-decoration: none;
    border-radius: 20px;
    margin-top: 20px;
    display: inline-block;
    font-size: 16px;
}
.joinUs a:hover {
    text-decoration: none;
    color: white;
}
.socialmedia-follow {
    margin: 80px 0;
}
.socialmedia-follow .follow_item {
    display: flex;
    align-items: center;
}
.socialmedia-follow .follow_item .fa-facebook-f {
    color: #0049d3;
}
.socialmedia-follow .follow_item .fa-instagram {
    color: #9e8b55;
}
.socialmedia-follow .follow_item .fa-twitter {
    color: #0fcbe3;
}
.socialmedia-follow .follow_item .fa {
    font-size: 70px;
    object-fit: scale-down;
    cursor: pointer;
}
.socialmedia-follow .follow_item div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-left: 30px;
    color: #3e3e3e;
}
.socialmedia-follow .follow_item div span {
    font-size: 14px;
    font-family: "RegularBodyRoman";
}
.socialmedia-follow .follow_item span:last-child {
    font-size: 25px;
}

/* home end  */

/* footer  */
.footer {
    background-color: #f8f8f8;
    padding: 50px 0 10px 0;
    font-family: "RegularBodyRoman";
}
.footer .footer-logo {
    width: 100%;
    height: 100%;
}
.footer .footer-logo ul {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    list-style: none;
    padding: 0;
}
.footer .footer-logo ul li {
    margin-right: 5px;
    text-align: center;
    width: 14%;
}
.footer .footer-logo ul li:last-child {
    margin: 0;
}
.footer .footer-logo ul li p {
    font-size: 9px;
    color: #198d7e;
}
.footer .footer-logo ul li img {
    width: 40px;
    height: 40px;
    object-fit: scale-down;
}
.footer .footer-logo img {
    width: auto;
    cursor: pointer;
    object-fit: scale-down;
    margin-bottom: 10px;
}
/* .footer-category ul{
    columns: 2;
  } */
.footer .footer-contact,
.footer .footer-category,
.footer .footer-account {
    width: max-content;
    margin: auto;
}
.footer .footer-contact p,
.footer .footer-category p,
.footer .footer-account p {
    color: #1a9c8c;
    font-size: 18px;
}

.footer .footer-contact ul,
.footer .footer-category ul,
.footer .footer-account ul {
    list-style: none;
    padding: 0;
}
.footer .footer-contact ul li a,
.footer .footer-category ul li a,
.footer .footer-account ul li a {
    color: #a4a4a4;
    text-decoration: none;
    font-size: 14px;
    font-family: "RegularBodyRoman" !important;
    font-style: initial;
}
.footer .footer-contact ul li:first-child a {
    font-weight: bolder !important;
}
.footer .footer-contact ul li a:hover,
.footer .footer-category ul li a:hover,
.footer .footer-account ul li a:hover {
    color: #1a9c8c;
}
.footer-hr {
    width: 100%;
    height: 2px;
    background-color: #9393936e;
    margin-top: 10px;
}
.copy-write {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0 14px;
}
.copy-write div {
    color: #a4a4a4;
    font-size: 12px;
    font-family: "RegularBodyRoman";
    width: 60%;
}
.copy-write ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
}
.copy-write ul li {
    /* margin: 0px 10px; */
    text-align: end;
}
.copy-write ul li img {
    width: 70%;
}
/* footer  end */

/* search page section  */

.related-product .product,
.search-page .product {
    text-align: center;
    border-radius: 10px;
    border: 1px solid gray;
    margin: 15px 0;
    cursor: pointer;
    transition: ease-in-out 0.3s;
    overflow: hidden;
}

.related-product .product:hover,
.search-page .product:hover {
    /* transform: translateY(-5px); */
    transition: ease-in-out 0.3s;
}

.related-product .product img,
.search-page .product img {
    object-fit: cover;
    width: 100%;
    height: 200px;
}
.search-page .products {
    /* padding: 40px 0px; */
}
.related-product .product h3,
.search-page .product h3 {
    color: #1a9c8c;
    font-size: 20px;
    font-style: italic;
    /* font-family: 'MyriadproBold'; */
    margin: 0;
    margin-top: 10px;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
/* .related-product .product button{
    display: block;
    width: 100%;
    padding: 10px;
    background: rgb(22,116,104);
    background: linear-gradient(98deg, rgba(22,116,104,1) 20%, rgba(26,155,139,0.9808298319327731) 81%);
  border: 1px solid transparent;
  /* border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; */
/* color: white;
      font-size: 20px;
      font-family: "MyriadProRegular";
  transition: ease-in-out 0.5s; 
  
  } */
/* .related-product .product:hover button,
  .search-page .product:hover button{
  
  background: white;
  color: #1A9C8C;
  border: 1px solid #1A9C8C;
  transition: ease-in-out 0.5s;
  } */
.related-product .product .product-namePrice,
.search-page .product .product-namePrice {
    /* width: fit-content; */
    margin: auto;
    /*margin-bottom: 20px;*/
    display: block;
    max-width: 90%;
}
.related-product .product .product-namePrice,
.search-page .product .product-namePrice p {
    /*height: 48px;*/
    text-overflow: ellipsis;
    overflow: hidden;
    line-clamp: 2;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.related-product .product .product-namePrice span,
.search-page .product .product-namePrice span {
    float: left;
    font-size: 20px;
    color: #626262;
    font-family: "RegularBodyRoman";
}

.productDetailsRate {
}

@media (max-width: 320px) {
    .productDetailsRate {
        text-align: center;
        flex-direction: column !important;
    }

    .productDetailsRate .ShareRow {
        margin-top: 10px !important;
    }
}

.prodictDetails-other .product-namePrice {
    /* width: fit-content; */
    margin-right: auto !important;
    margin-left: 0 !important;
}

.product-namePrice .product-Price-rate {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.product-namePrice .product-Price-rate ul {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
}
.product-namePrice .product-Price-rate ul li {
    margin: 0 1px;
}
.product-namePrice .product-Price-rate ul li:last-child {
    margin-right: 0;
}
.product-namePrice .product-Price-rate ul li .fa {
    color: #626262;
    font-size: 14px;
}
.fade {
    opacity: 1 !important;
}
.addCartWish {
    display: flex;
    background: rgb(22, 116, 104);
    background: linear-gradient(
        98deg,
        rgba(22, 116, 104, 1) 20%,
        rgba(26, 155, 139, 0.9808298319327731) 81%
    );
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.related-product .product button,
.search-page .products .product button {
    display: block;
    width: 100%;
    padding: 10px 0;
    background-color: transparent;
    border: 1px solid transparent;
    color: white;
    font-size: 15px;
    font-family: "RegularBodyRoman";
    transition: ease-in-out 0.5s;
}
.addCartWish button:first-child {
    border-bottom-left-radius: 8px;
    border-right: 1px solid white !important;
}
.addCartWish button:last-child {
    border-bottom-right-radius: 8px;
    border-left: 1px solid white !important;
}
.addCartWish button:last-child:hover,
.addCartWish button:first-child:hover {
    background: white;
    border-color: #1a9c8c;
    color: #1a9c8c;
    transition: ease-in-out 0.3s;
}
.addCartWish-other .red,
.addCartWish .red {
    color: red;
}

.addCartWish .fa {
    font-size: 20px;
}
.cartIcon-img {
    width: 36px;
    height: 34px;
}
.filter {
    padding: 40px 0;
    box-shadow: 4px 0px 5px 0px #c0c0c026;
    height: 100%;
    margin-right: 15px;
}
.filter h4 {
    text-align: center;
    color: #1a9c8c;
    margin-bottom: 25px;
    padding-bottom: 25px;
    font-family: "RegularBodyRoman";
    font-size: 25px;
}
.filter .rate,
.filter .size,
.filter .colors,
.filter .price {
    position: relative;
    padding: 10px 30px;
    margin-bottom: 20px;
}
.filter .filterTitle {
    position: relative;
}
.filter .filterTitle .hr {
    position: absolute;
    width: 105%;
    bottom: 0px;
    background-color: #e3e3e3;
    height: 1px;
    margin-left: -15px;
}
.filter .colors p {
    margin-top: 30px;
}
.filter .rate p,
.filter .size p,
.filter .colors p,
.filter .price p {
    font-size: 25px;
    color: #676767;
    font-family: "RegularBodyRoman";
    margin-bottom: 20px;
}
.filter .price .input-range__slider,
.filter .price .input-range__track--active {
    background-color: #a4a4a4;
    border: #a4a4a4;
}
.filter .input-range__label--min,
.filter .input-range__label--max {
    display: none;
}
.filter .input-range__label-container {
    top: -6px;
    /* top: -3px; */
    font-family: "RegularBodyRoman";
}
/* .filter .input-range__label-container .span{
    padding: 5px;
    background-color: silver;
    color: white;
    font-size: 16px;
    border-radius: 10px;
  } */

.filter .size ul,
.filter .colors ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding: 0;
    flex-wrap: wrap;
}
.filter .colors ul li {
    border-radius: 50px;
    padding: 5px;

    cursor: pointer;
    width: 25%;
}
.filter .colors ul .borderOn,
.filter .colors ul li:hover {
    transition: ease-in-out 0.3s;
}
.filter .colors ul li .colorDiv-border {
    border-radius: 50px;
    padding: 5px;
    width: fit-content;
    margin: auto;
    border: 1px solid #ffffff00;
    transition: ease-in-out 0.3s;
}
.filter .colors ul li .borderOn,
.filter .colors ul li .colorDiv-border:hover {
    border: 1px solid #989898;
}
.filter .colors ul li div div {
    height: 25px;
    width: 25px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 2px 0px black;
}
.filter .colors ul li div .fa {
    color: #5a5a5a;
    font-size: 12px;
}

.filter .size ul li {
    width: 10%;
    cursor: pointer;
}
.filter .size ul li div {
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 15px;
    width: fit-content;
    margin: auto;
    /* font-family: "MyriadproBold"; */
    color: #676767;
    position: relative;
}

.filter .size ul li p {
    border: 2px solid silver;
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    position: absolute;
    transition: ease-in-out 0.3s;
}
.filter .size ul li p:hover {
    border-color: #127c6f;
    transition: ease-in-out 0.3s;
}
.filter .size ul li .sizeSelected {
    background-color: #1a9c8cbf;
    border: 2px solid #127c6f;
}

.filter .rate div {
    display: flex;
    align-items: center;
    margin: 10px 0;
}
.filter .rate div ul {
    padding-left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    margin: 0;
}
.filter .rate div ul li {
    margin: 0 5px;
}
.filter .rate div ul li .fa {
    color: #dbdbdb;
    font-size: 20px;
}
.filter .rate div p {
    width: 30px;
    height: 30px;
    border: 2px solid #e3e3e3;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    cursor: pointer;
    transition: ease-in-out 0.3s;
}
.filter .rate div .rateBorder,
.filter .rate div p:hover {
    border-color: #1a9c8c;
    transition: ease-in-out 0.3s;
}
.filter .rate div p .fa {
    font-size: 14px;
    color: #1a9c8c;
}

.filter .applyButton {
    margin-top: 40px;
    text-align: center;
}
.filter .applyButton a {
    color: #1a9c8c;
    padding: 10px 25px;
    border: 2px solid #1a9c8c;
    border-radius: 20px;
    text-decoration: none;
    font-size: 16px;
    /* font-family: "MyriadproBold"; */
    transition: ease-in-out 0.5s;
}
.filter .applyButton a:hover {
    background-color: #1a9c8c;
    color: white;
    transition: ease-in-out 0.5s;
}
.search-page .ShopBYCategory {
    margin: 40px 0px;
}

.SubCateContiner {
    padding-right: 60px !important;
    padding-left: 60px !important;
}
.SubCateContiner .react-multi-carousel-item {
    padding: 5px 20px;
}
.emptyProduct {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.noProductToShow {
    margin: auto;
    font-size: 30px;
    justify-content: center;
    align-items: center;
    padding-right: 30px;
    color: #127c6f;
    font-family: "RegularBodyRoman";
}
.search-page .otherShow {
    text-align: center;
    border-radius: 10px;
    border: 1px solid #c9c9c9;
    margin: 15px 0;
    cursor: pointer;
    transition: ease-in-out 0.3s;
    display: flex;
}
.search-page .otherShow .product-namePrice {
    text-align: left;
}
.search-page .otherShow .product-namePrice .product-Price-rate {
    flex-direction: column;
    align-items: baseline;
}
.search-page .otherShow .product-namePrice .product-Price-rate .pro-desc {
    color: #8e8e8e;
    font-size: 16px;
    margin: 15px 0;
}
.prodictDetails-other {
    padding: 20px 0;
}
.addCartWish-other {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 300px;
}
.search-page .addCartWish-other button {
    padding: 10px 25px;
    background: rgb(22, 116, 104);
    background: linear-gradient(
        98deg,
        rgba(22, 116, 104, 1) 20%,
        rgba(26, 155, 139, 0.9808298319327731) 81%
    );
    border: 1px solid #1a9c8c;
    color: white !important;
    font-size: 19px;
    /* font-family: "MyriadProRegular"; */
    border-radius: 10px;
    text-decoration: none;
    transition: ease-in-out 0.5s;
}
.search-page .addCartWish-other button:first-child {
    margin-right: 10px;
}
.search-page .addCartWish-other button:hover {
    color: #1a9c8c !important;
    background: white;
    border: 1px solid #1a9c8c;
    transition: ease-in-out 0.5s;
}
.search-page .otherShow img {
    margin-right: 60px;
    border-radius: 10px;
    max-width: 300px;
    width: auto;
    height: auto;
    object-fit: cover;
}
/* search page section  */

/* product details  */
.product-details {
    padding-top: 50px;
}
.product-details-rigth {
    padding: 40px;
}
.product-details-rigth p {
    font-size: 16px;
    font-family: "RegularBodyRoman";
}
.product-details-rigth h4 {
    color: #1a9c8c;
    font-size: 24px;
    font-family: "RegularItalic";
    margin-bottom: 30px;
    text-transform: capitalize;
}
.product-details-rigth {
    color: #606060;
}
.product-details-rigth .rate {
    border-bottom: 1px solid #c0c0c0;
    margin-bottom: 40px;
    margin-top: 40px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "RegularBodyRoman";
}
.product-details-rigth .rate span {
    font-size: 16px;
}
.product-details-rigth .rate ul {
    list-style: none;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0;
    margin-top: 10px;
}
.product-details-rigth .rate ul li {
    margin: 0px 1px;
}
.product-details-rigth .rate ul li .fa {
    font-size: 20px;
    color: #1a9c8c !important;
}
.product-details-rigth .product-options {
    display: flex;
    align-items: flex-end;
}
.product-details-rigth .product-options .priceProduct {
    margin-bottom: 0;
    font-size: 32px;
    font-weight: bold;
    /* margin-left: 50px; */
}
.product-details-rigth .product-options ul {
    list-style: none;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0;
    font-size: 14px;
    margin-right: 50px;
}
.product-details-rigth .product-options ul li {
    margin: 0px 10px;
}
.product-details-rigth .product-options ul li select {
    padding: 2px 12px;
    border-radius: 10px;
    font-family: "RegularBodyRoman";
}
.product-options .numOfProducts {
    border-radius: 10px;
    border: 1px solid #787878;
    padding: 1px 10px;
}
.product-options .numOfProducts input {
    border: none;
    text-align: center;
    width: 40px;
    color: #3e3e3e;
}
.product-options .numOfProducts span {
    cursor: pointer;
}
.product-details-rigth .product-options ul li:last-child select {
    border-radius: 10px;
    padding: 3px 10px;
    color: #3e3e3e;
    width: 135px;
    background-color: white;
}
button:focus,
input:focus,
select:focus,
textarea:focus {
    outline: none !important;
}
.footer-prodDetails {
    /* display: flex;
  justify-content: space-between;
  align-items: center; */
    margin-top: 40px;
}

.footer-prodDetails p {
    margin: 0;
    color: #626262;
    font-size: 30px;
    font-weight: bold;
}
.footer-prodDetails .addToCartDetalis {
    padding: 10px 25px;
    background: rgb(22, 116, 104);
    background: linear-gradient(
        98deg,
        rgba(22, 116, 104, 1) 20%,
        rgba(26, 155, 139, 0.9808298319327731) 81%
    );
    border: 1px solid #1a9c8c;
    color: white !important;
    font-size: 19px;
    font-family: "RegularBodyRoman";
    border-radius: 10px;
    text-decoration: none;
    transition: ease-in-out 0.5s;
    text-align: center;
    max-width: 215px;
    /* margin-right: 15px; */
    cursor: pointer;
}
.footer-prodDetails .addToCartDetalis:hover {
    text-decoration: none;
    color: #1a9c8c !important ;
    background: white;
    border: 1px solid #1a9c8c;
    transition: ease-in-out 0.5s;
}
.footer-prodDetails .soldOut:hover,
.footer-prodDetails .soldOut {
    background: none;
    border: 0;
    background-color: silver;
    cursor: not-allowed;
    color: white !important;
    margin-right: 20px;
}

.whatsAppButton {
    border: 1px solid rgb(0, 255, 157);
    padding: 10px 25px;
    display: block;
    font-size: 17px;
    border-radius: 10px;
    transition: ease-in-out 0.5s;
    background: rgb(0, 255, 157);
    background: linear-gradient(
        98deg,
        rgba(0, 255, 157, 1) 20%,
        rgba(26, 155, 139, 1) 81%
    );
    color: #585858 !important;
    font-family: "RegularBodyRoman";
    text-align: center;
    max-width: 250px;
    margin-right: 15px;
}
.whatsAppButton:hover {
    color: #fff !important;
}
.footer-prodDetails .col {
    /* display: flex;
  align-items: center; */
    cursor: pointer;
    font-size: 14px;
}
.footer-prodDetails div .fa {
    margin-right: 5px;
    font-size: 20px;
    margin-top: 15px;
    cursor: pointer;
}
.footer-prodDetails div .fa-heart {
    color: red;
}

/* .footer-prodDetails div span{
    font-family: "MyriadproBold";
  } */
.image-gallery-slides {
    max-height: 520px;
}
/* .image-gallery-slides img{
height: -webkit-fill-available;
  } */
.product-details .image-gallery-thumbnails-wrapper {
    min-height: 350px;
}
.product-details .image-gallery-thumbnail {
    border: 2px solid silver;
    border-radius: 10px !important;
    padding: 0 15px !important;
    height: 75px !important;
}
.product-details .image-gallery-thumbnail img {
    height: 100%;
    object-fit: scale-down;
}
.product-details .image-gallery-thumbnail-image {
    border-radius: 10px;
}
.product-details .image-gallery-thumbnail.active,
.product-details .image-gallery-thumbnail:hover,
.product-details .image-gallery-thumbnail:focus {
    border: 2px solid #1a9c8c !important;
}
.productDetails-top {
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 40px;
}

.related-product {
    margin-top: 50px;
}
.related-product h2 {
    color: #1a9c8c;
    margin-bottom: 40px;
    /* font-family: 'MyriadproBold'; */
}

.moreProdDetails {
    margin: 0px 10px;
    margin-bottom: 20px;
}
.moreProdDetails li span {
    font-size: 16px;
    font-family: "RegularBodyRoman";
}
.moreProdDetails li span:first-child {
    font-weight: 700 !important;
    font-size: 17px;
    margin-right: 10px;
}

.no-padd .Or {
    padding: 0px 20px;
    font-family: "RegularBodyRoman";
    font-size: 18px;
    font-weight: 500;
}

.specialOrderButton a {
    display: block !important;
    /* font-size: 19px;
  font-family: "RegularBodyRoman";
  margin-top: 10px;
  color: #1a9c8c; */

    /* /////// */

    padding: 10px 25px;
    background: rgb(22, 116, 104);
    background: linear-gradient(
        98deg,
        rgba(22, 116, 104, 1) 20%,
        rgba(26, 155, 139, 0.9808298319327731) 81%
    );
    border: 1px solid #1a9c8c;
    color: white !important;
    font-size: 19px;
    font-family: "RegularBodyRoman";
    border-radius: 10px;
    text-decoration: none;
    transition: ease-in-out 0.5s;
    text-align: center;
    max-width: 215px;
    margin-right: 15px;
    cursor: pointer;
}
.addToCartButtons {
    display: flex;
    align-items: flex-start;
    /* justify-content: space-between; */
    flex-direction: column;
    margin-bottom: 15px;
}

.addToCartButtons div {
    width: 100%;
}
.addToCartButtons div a {
    display: block;
    margin-top: 15px;
    max-width: 248px !important;
    font-size: 17px !important;
}

.addToCartButtons .addToCartDetalis {
    max-width: 248px !important;
    font-size: 17px !important;
}
.specialOrderButton a:hover {
    text-decoration: none;
    color: #1a9c8c !important;
    background: white;
    border: 1px solid #1a9c8c;
    transition: ease-in-out 0.5s;
}
.descr-review-product {
    margin-top: 50px;
}
.descr-review-product .review {
    display: flex;
    justify-content: end;
    align-items: end;
    padding: 50px 50px 0;
}
.descr-review-product .nav-tabs .nav-link {
    border: 1px solid #ebebeb;
    border-top-right-radius: 0;
    padding: 15px 25px;
    color: #817d7d;
    font-family: "RegularBodyRoman";
    background-color: #efefef;
    font-size: 14px;
}
.descr-review-product .nav-tabs .nav-link:first-child {
    border-top-left-radius: 25px;
}
.descr-review-product .nav-tabs .nav-link:last-child {
    border-top-right-radius: 25px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link.active {
    color: #817d7d !important;
    background-color: #fff;
    outline: none !important;
}

.descr-review-product .nav-tabs {
    border: 0;
}
.descr-review-product .tab-content {
    border: 1px solid #d5d5d5 !important;
    border-radius: 25px !important;
    border-top-left-radius: 0 !important;
    margin-bottom: 30px;
    font-family: "RegularBodyRoman";
}
.descr-review-product .tab-content > .active {
    padding-bottom: 60px;
}
.descr-review-product .review img {
    margin-right: 25px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}
.descr-review-product .review-detalis {
    width: 60%;
    padding: 30px;
    border: 1px solid #d5d5d5;
    border-radius: 40px;
    position: relative;
}
.descr-review-product .review-detalis p {
    font-size: 16px;
}
.descr-review-product .review-detalis span {
    font-size: 14px;
}
.descr-review-product .review-detalis:before {
    content: "";
    position: absolute;
    left: -18px;
    top: auto;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 16px solid #d5d5d5;
    clear: both;
    transform: rotate(91deg);
    margin-top: 5px;
}
.descr-review-product .review-detalis:after {
    content: "";
    position: absolute;
    left: -16px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 11px solid transparent;
    border-top: 19px solid #fff;
    clear: both;
    transform: rotate(92deg);
    top: 34px;
}
.descr-review-product .review-detalis .review-detalis-p {
    color: #a2a2a2;
    /* font-family: "MyriadproBold"; */
    margin-bottom: 20px;
}
.descr-review-product .review-detalis .top-review-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.descr-review-product .review-detalis h6 {
    /* font-family: "MyriadproBold"; */
    color: #ff0000;
    cursor: pointer;
    transition: ease-in-out 0.3s;
}
.descr-review-product .review-detalis h6:hover {
    color: #127c6f;
    transition: ease-in-out 0.3s;
}
.descr-review-product .review-detalis .top-review-details p {
    /* font-family: "MyriadproBold"; */
    color: #817d7d;
    margin: 0;
}
.descr-review-product .review-detalis .top-review-details span {
    color: #d5d5d5;
    /* font-family: 'MyriadproBold'; */
}
.descr-review-product .review-detalis .top-review-details .rate-review {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.descr-review-product .review-detalis .top-review-details .rate-review ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    margin: 0;
    padding-left: 15px;
}
.descr-review-product .review-detalis .top-review-details .rate-review span {
    color: #817d7d;
}
.product-details .fa,
.descr-review-product .fa {
    /* color: #A4A4A4; */
    margin-right: 2px;
}
.review-detalis .review-reply {
    padding: 15px;
    margin-top: 30px;
    border: 1px solid #b6b6b6;
    border-radius: 25px;
    background-color: #f7f7f7;
}
.review-detalis .review-reply .review-detalis {
    width: 100%;
    border: 0;
    padding: 10px;
}
.review-detalis .review-reply .review-detalis:after,
.review-detalis .review-reply .review-detalis::before {
    display: none;
}

.product-details .review-form {
    margin-top: 50px;
}
.product-details .review-form label {
    display: block;
    color: #676767;
    /* font-family: 'MyriadproBold'; */
    font-size: 16px;
}
.product-details .review-form a {
    padding: 10px 45px;
    background-color: #1a9c8c;
    color: white;
    border-radius: 20px;
    /* font-family: "MyriadproBold"; */
    text-decoration: none;
    transition: ease-in-out 0.3s;
    border: 1px solid #fff;
    font-size: 16px;
}
.product-details .review-form a:hover {
    text-decoration: none;
    background-color: white;
    color: #1a9c8c;
    border: 1px solid #1a9c8c;
    transition: ease-in-out 0.3s;
}
.product-details .review-form textarea,
.product-details .review-form input {
    padding: 10px 20px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #d5d5d5;
    background-color: #fafafa;
    margin-bottom: 30px;
    appearance: none;
    font-size: 16px;
}
.product-details .review-form textarea {
    min-height: 200px;
}

.product-details .review-form textarea::placeholder,
.product-details .review-form ::placeholder {
    color: #a4a4a4;
}
.descr-review-product .choose-review-rate {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.descr-review-product .choose-review-rate .rate-review {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.descr-review-product .choose-review-rate .rate-review label {
    margin-bottom: 0;
}
.descr-review-product .choose-review-rate ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding-left: 10px;
    margin: 0;
}
.descr-review-product .choose-review-rate ul .fa {
    cursor: pointer;
    font-size: 14px;
}
.rate-review .fa,
.descr-review-product .choose-review-rate ul .fa-star {
    color: #1a9c8c;
    font-size: 14px;
}
.desc_review {
    padding: 50px;
    font-size: 16px;
    color: darkslategray;
    font-family: "RegularBodyRoman";
}
.desc_review a {
    color: #1a9c8c;
    font-size: 16px;
}
.desc_review a:hover {
    color: #127c6f;
}
/* product details  end */
.swal-modal {
    font-family: "RegularBodyRoman";
    z-index: 99999999999999 !important;
}
.modal {
    z-index: 99 !important;
}
.modal-backdrop {
    z-index: 9 !important;
}
.swal-button--cancel {
    color: white;
    /* padding-bottom: 13px; */
}
.swal-footer {
    text-align: center;
}
.modal-dialog {
    font-family: "RegularBodyRoman";
}
.react-multi-carousel-list {
    direction: ltr;
}

.LoginPage {
    font-family: "RegularBodyRoman";
}
.float-r img {
    object-fit: scale-down;
}

.home .carousel-control-next-icon {
    background-image: url("/img/right-arrow.svg") !important;
}
.carousel-control-prev-icon {
    background-image: url("/img/left-arrow.svg") !important;
}
/*.product-namePrice .product-Price-rate {
    height: 13px !important;
}*/
/* @media  */
@media only screen and (min-width: 1500px) {
    .related-product .col-lg-3 {
        min-width: 235px !important;
        /* max-width: 320px; */
        max-width: 280px !important;
    }
}
@media only screen and (max-width: 1500px) {
    /* .no-padUnder-1500{
      padding: 0 !important;
    } */
    .title-section h4 {
        font-size: 20px;
        font-size: 30px;
    }
    .filter .rate,
    .filter .size,
    .filter .colors,
    .filter .price {
        padding: 10px 30px;
    }
    .filter .size ul li div {
        padding: 5px 11px;
    }
}
@media only screen and (max-width: 1500px) and (min-width: 1200px) {
    .filter .rate div ul {
        padding-left: 5px;
    }
    .filter .rate div ul li {
        margin: 0 3px;
    }
    .filter .rate div ul li .fa {
        font-size: 18px;
    }
    .search-page .products .product button {
        font-size: 17px;
    }
    .filter .rate,
    .filter .size,
    .filter .colors,
    .filter .price {
        padding: 10px 15px;
    }
}
@media only screen and (max-width: 1200px) {
    .container-fluid {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }
    .logo img {
        width: 100%;
        object-fit: scale-down;
    }
    .language-dropdown .dropdown-toggle img {
        width: 25px;
    }
    .language-name {
        font-size: 12px;
    }

    .Header .user-header img {
        margin-right: 10px;
    }
    .Header .user-signin .btn-primary h6 {
        font-size: 13px;
    }
    .Header .user-signin p {
        margin: 0;
        font-size: 13px;
    }
    /* .Header .header-cart .cartIcon {
    margin-right: 5px;
  } */
    .Header .header-cart p {
        font-size: 16px;
    }
    .Header .cartIcon img {
        width: 30px;
    }
    .Header .header-cart .cartIcon span {
        top: 20%;
        font-size: 12px;
    }
    .title-section h4 {
        font-size: 15px;
        font-size: 24px;
    }
    .shop-category {
        height: 300px;
    }
    .shop-category img {
        max-height: 180px;
    }
    .shop-category-details h5 {
        font-size: 22px;
    }
    .shop-category-details span {
        font-size: 13px;
    }
    .shop-category-details p {
        font-size: 18px;
    }

    /* search page  */
    .related-product .product img,
    .related-product .product h3,
    .search-page .product h3 {
        font-size: 20px;
    }
    .related-product .product button,
    .related-product .product .product-namePrice span,
    .search-page .products .product button,
    .search-page .product .product-namePrice span {
        font-size: 16px;
    }
    .search-page .otherShow img {
        width: auto;
        height: auto;
    }
    .search-page .otherShow .addCartWish-other button {
        width: 46%;
    }
    .product-details-rigth {
        padding: 30px;
    }
    .footer-prodDetails .addToCartDetalis,
    .whatsAppButton {
        font-size: 16px;
        padding: 10px 18px;
    }
    .product-details-rigth .product-options .priceProduct {
        font-size: 30px;
    }
}
@media only screen and (max-width: 992px) {
    /* header */
    .Header .header-cart p {
        display: none;
    }
    /* .no-padUnder-1500{
      padding-left: 15px !important;
      padding-right: 15px !important;
    } */
    .logo-col {
        padding-right: 0px !important;
    }
    .search-div {
        height: 100%;
    }
    .search-div .mb-3,
    .search-div .my-3 {
        height: 100%;
    }
    .Header .input-group > .custom-file,
    .input-group > .custom-select,
    .input-group > .form-control,
    .input-group > .form-control-plaintext {
        height: 100%;
    }
    .Header .input-group-prepend {
        height: 100% !important;
    }
    .search-div .input-group-append {
        height: 100%;
    }
    .Header .input-group > .input-group-append > .btn,
    .input-group > .input-group-append > .input-group-text,
    .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
    .input-group
        > .input-group-prepend:first-child
        > .input-group-text:not(:first-child),
    .input-group > .input-group-prepend:not(:first-child) > .btn,
    .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Header .dropdown-menu {
        font-size: 13px;
    }
    .bottom-header-content-handmade h5 {
        font-size: 18px;
    }
    .bottom-header-handmade span {
        font-size: 12px;
    }
    .bottom-header-content-handmade ul li a {
        font-size: 12px;
    }
    /* .bottom-header-content-handmade ul, .bottom-header-content ul{
    padding-left: 20px;
  } */

    /* home  */
    .collection-item p {
        padding-bottom: 15px;
        font-size: 18px;
    }

    .shop-category p {
        font-size: 22px;
    }
    .shop-category-details h5 {
        font-size: 22px;
    }
    .shop-category p {
        font-size: 20px;
    }
    .joinUs {
        height: 270px;
    }
    .joinUs h4 {
        font-size: 30px;
    }
    .joinUs p {
        font-size: 18px;
    }
    .joinUs a {
        font-size: 15px;
    }

    .socialmedia-follow .follow_item .fa {
        font-size: 60px;
    }
    .socialmedia-follow .follow_item span:first-child {
        font-size: 12px;
    }
    .socialmedia-follow .follow_item span:last-child {
        font-size: 20px;
    }
    .socialmedia-follow .follow_item div {
        margin-left: 15px;
    }
    .product-details-rigth h4 {
        font-size: 20px;
    }
    .product-details-rigth p {
        font-size: 14px;
    }
    .product-details-rigth .rate ul li .fa {
        font-size: 18px;
    }
    .footer-prodDetails p {
        font-size: 18px;
    }
    .whatsAppButton,
    .footer-prodDetails .addToCartDetalis {
        padding: 5px 9px;
        font-size: 15px;
    }
    .footer-prodDetails div span {
        font-size: 14px;
    }
    .descr-review-product .review-detalis .review-detalis-p {
        font-size: 14px;
    }
    .descr-review-product .review-detalis {
        width: 85%;
    }
    .Header .input-group > .custom-select:not(:first-child),
    .input-group > .form-control:not(:first-child) {
        min-height: auto;
        height: auto;
        padding: 2px;
    }
    .Header
        .input-group
        > .input-group-append:last-child
        > .btn:not(:last-child):not(.dropdown-toggle),
    .input-group
        > .input-group-append:last-child
        > .input-group-text:not(:last-child),
    .input-group > .input-group-append:not(:last-child) > .btn,
    .input-group > .input-group-append:not(:last-child) > .input-group-text,
    .input-group > .input-group-prepend > .btn,
    .input-group > .input-group-prepend > .input-group-text {
        font-size: 12px;
    }
    .Header .dropdown-menu .dropdown-item {
        font-size: 11px;
    }

    .footer .footer-logo ul li {
        margin-right: 5px;
        text-align: center;
        width: 22%;
    }
    .product-details-rigth .product-options .priceProduct {
        font-size: 26px;
        /* margin-left: 30px; */
    }
    .search-page .otherShow img {
        height: inherit;
        width: 165px;
        margin-right: 30px;
    }
    .landing-page-img {
        height: 400px;
    }
    .specialOrderButton a {
        max-width: 150px;
        font-size: 16px;
        padding: 5px;
    }
    .footer-prodDetails div .fa {
        margin-top: 5px;
    }
    .related-product .product button,
    .related-product .product .product-namePrice span,
    .search-page .products .product button,
    .search-page .product .product-namePrice span {
        font-size: 18px;
    }
    .filter .rate div p {
        width: 25px;
        height: 25px;
        border: 2px solid #e3e3e3;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        cursor: pointer;
        transition: ease-in-out 0.3s;
    }
    .fa-star:before {
        content: "\f005";
        font-size: 17px !important;
    }
    .form-control__phone-countryCode {
        width: 42% !important;
    }
    .input-group-text-phone-countryCode {
        width: 8%;
    }
    .search-web {
        /* display: none !important; */
        visibility: hidden;
    }
    .search-mobile {
        display: block !important;
    }
}
@media only screen and (max-width: 768px) {
    .footer .footer-logo ul li img {
        margin-bottom: 3px;
    }
    /* header  */
    .Header .user-header img {
        /* margin-right: 5px; */
        /* margin: 0 10px; */
    }
    .Header .user-signin p {
        font-size: 12px;
    }
    .Header .user-signin .btn-primary h6 {
        font-size: 13px;
    }
    .no-padUnder-768 {
        padding: 0 !important;
    }
    .Header
        .input-group
        > .input-group-append:last-child
        > .btn:not(:last-child):not(.dropdown-toggle),
    .input-group
        > .input-group-append:last-child
        > .input-group-text:not(:last-child),
    .input-group > .input-group-append:not(:last-child) > .btn,
    .input-group > .input-group-append:not(:last-child) > .input-group-text,
    .input-group > .input-group-prepend > .btn,
    .input-group > .input-group-prepend > .input-group-text {
        padding: 0 5px !important;
        font-size: 12px;
        margin-bottom: 0;
    }
    .Header .input-group > .custom-select:not(:first-child),
    .input-group > .form-control:not(:first-child) {
        height: inherit;
    }
    .Header .input-group > .input-group-append > .btn,
    .input-group > .input-group-append > .input-group-text,
    .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
    .input-group
        > .input-group-prepend:first-child
        > .input-group-text:not(:first-child),
    .input-group > .input-group-prepend:not(:first-child) > .btn,
    .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
        padding: 5px;
    }
    .Header .input-group > .custom-file,
    .input-group > .custom-select,
    .input-group > .form-control,
    .input-group > .form-control-plaintext {
        padding: 5px;
    }

    .Header .dropdown-menu {
        font-size: 12px;
    }

    .search-div .input-group-append img {
        width: 15px;
    }
    .cart-col {
        padding-left: 0px !important ;
    }
    /* .bottom-header-content-handmade h5{
    padding-left: 0;
  }
  .bottom-header-content-handmade h5 {
    font-size: 16px;
  }
  .bottom-header-content-handmade li {
    margin: 0px 5px;
  }
  .bottom-header-content-handmade ul{
    padding-left: 10px;
  
  } */
    .bottom-header-handmade {
        display: none;
    }
    .collection-item p {
        padding-bottom: 10px;
        font-size: 15px;
    }
    .socialmedia-follow .follow_item .fa {
        font-size: 40px;
    }
    .socialmedia-follow .follow_item div {
        margin-left: 10px;
    }
    .socialmedia-follow .follow_item span:first-child {
        font-size: 11px;
    }
    .socialmedia-follow .follow_item span:last-child {
        font-size: 15px;
    }
    .no-padd-under767 {
        padding: 0 !important;
    }
    .footer .footer-logo {
        width: 60%;
        margin: auto;
        margin-bottom: 35px;
        object-fit: scale-down;
    }
    .footer .footer-logo img {
        width: 100%;
        cursor: pointer;
        object-fit: scale-down;
        margin-bottom: 0px;
    }
    .footer .footer-logo ul {
        justify-content: center;
    }
    .copy-write ul li {
        margin: 0;
    }
    .copy-write div {
        font-size: 11px;
    }
    .filter {
        margin: 0;
    }
    .filter .size ul,
    .filter .colors ul {
        justify-content: end;
    }
    .filter .colors ul li {
        width: 7%;
    }
    .filter .rate p,
    .filter .size p,
    .filter .colors p,
    .filter .price p {
        margin-bottom: 20px;
    }
    .filter .size ul li {
        width: 15%;
    }
    .filter .filterTitle .hr {
        width: 100%;
        margin-left: 0px;
    }
    .filter .input-range {
        width: 90%;
        margin: auto;
    }
    .product-details .image-gallery-thumbnails-wrapper {
        min-height: 300px;
    }

    .product-details-rigth {
        padding: 40px 20px;
    }
    .descr-review-product .review-detalis {
        padding: 20px;
    }
    .descr-review-product .review-detalis .top-review-details {
        margin-bottom: 10px;
    }
    .descr-review-product .review-detalis .top-review-details p {
        font-size: 14px;
    }
    .descr-review-product .review-detalis .top-review-details span {
        font-size: 14px;
    }
    .descr-review-product
        .review-detalis
        .top-review-details
        .rate-review
        span {
        font-size: 14px;
    }
    .descr-review-product .review-detalis .top-review-details .rate-review ul {
        padding-left: 7px;
    }
    .descr-review-product .review {
        padding: 30px;
    }
    .descr-review-product .review-detalis .review-detalis-p {
        font-size: 12px;
    }
    .descr-review-product .review img {
        margin-right: 20px;
        width: 70px;
        height: 70px;
    }
    .descr-review-product .review-detalis:after {
        left: -15px;
        top: 26px;
        border-left: 12px solid transparent;
        border-right: 11px solid transparent;
    }
    .descr-review-product .review-detalis:before {
        left: -17px;
        margin-top: 8px;
    }
    /* .Header .user-header,
  .language-dropdown{
    margin:  0 10px;
  }
  .Header .header-cart{
    margin-left : 10px
  } */
    .no-padd-under567 {
        padding: 0 !important;
    }
    .Header .user-header,
    .language-dropdown {
        margin: 0 5px;
    }
    .Header .header-cart {
        margin-left: 0px;
    }
    .footer .footer-contact,
    .footer .footer-category,
    .footer .footer-account {
        margin: 10px 0;
    }
    .search-web {
        display: none;
    }
    .search-mobile {
        display: block;
        margin-left: 20px;
    }
    .swal-button--cancel {
        /* padding-bottom: 5px !important; */
    }
    .Profile .CartTabsP {
        margin-top: 20px;
    }

    .search-page .otherShow {
        flex-direction: column;
    }
    .search-page .otherShow img {
        width: 100%;
        margin: auto !important;
        object-fit: cover;
    }
    .search-page .otherShow p {
        text-align: center;
    }
    .search-page .otherShow .product-namePrice .product-Price-rate .pro-desc {
        font-size: 14px;
        text-align: center;
        margin: 10px 0;
    }

    .prodictDetails-other {
        padding: 10px 0;
    }
    .search-page .otherShow .product-namePrice .product-Price-rate {
        align-items: center;
    }
    .addCartWish-other {
        justify-content: center;
        margin: auto;
    }
    .search-page .otherShow .product-namePrice {
        text-align: center;
    }
    .prodictDetails-other .product-namePrice {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .ButtonCost a:last-child {
        float: left;
    }
    .landing-page-img {
        height: 300px;
    }
    .Or {
        padding-left: 2%;
    }
    .title-section h4 {
        font-size: 18px;
    }
    .fa-star:before {
        content: "\f005";
        font-size: 20px !important;
    }
    .form-control__phone-countryCode {
        width: 70% !important;
    }
    .input-group-text-phone-countryCode {
        width: 10%;
    }
    .filter {
        padding: 40px 0;
        box-shadow: unset;
        height: 100%;
        margin-right: 15px;
        border: 1px solid silver;
    }
}
@media only screen and (max-width: 576px) {
    /* header  */
    /* .search-web{
      display: none;
    } */
    /* .search-mobile{
      display: block;
      margin-right: auto;
      margin-left: auto;
      margin-top: 15px;
    } */
    .bottom-header-content-handmade ul,
    .bottom-header-content ul {
        padding-left: 10px;
    }
    .bottom-header-content-handmade ul li a,
    .bottom-header-content ul li a {
        font-size: 11px;
    }
    .toggle-header button img {
        width: 25px;
    }
    .toggle-header span {
        font-size: 11px;
        margin-left: 3px;
    }
    .bottom-header-content ul li {
        margin: 0px 5px;
    }
    .collection-item p {
        padding-bottom: 10px;
        font-size: 13px;
    }
    /* .shop-category{
    width: 50%;
    margin: auto;
  } */
    .shop-category p {
        font-size: 16px;
    }
    .shop-category img {
        max-height: 115px;
    }
    .shop-category-details h5 {
        font-size: 16px;
    }
    .shop-category-details span {
        font-size: 9px;
    }
    .shop-category-details p {
        font-size: 14px;
        margin-bottom: 0;
    }
    .shop-category-details {
        padding: 15px;
    }
    .joinUs {
        height: 200px;
    }
    .joinUs h4 {
        font-size: 27px;
    }
    .joinUs p {
        font-size: 16px;
    }
    .socialmedia-follow .follow_item .fa {
        font-size: 30px;
    }
    .socialmedia-follow .follow_item span:first-child {
        font-size: 9px;
    }
    .socialmedia-follow .follow_item span:last-child {
        font-size: 13px;
    }
    .footer .footer-contact p,
    .footer .footer-category p,
    .footer .footer-account p {
        font-size: 15px;
        margin-bottom: 5px;
    }
    .footer .footer-contact ul li a,
    .footer .footer-category ul li a,
    .footer .footer-account ul li a {
        font-size: 12px;
    }
    .copy-write ul li {
        margin: 0 5px;
        width: 35px;
    }
    .copy-write ul li img {
        width: 100%;
    }
    .copy-write div {
        font-size: 9px;
        width: 75%;
    }
    .footer .footer-logo {
        width: 65%;
    }
    .filter .colors ul li {
        width: 8%;
    }
    .filter .colors ul li div div {
        height: 23px;
        width: 23px;
    }
    .descr-review-product .review {
        padding: 20px;
    }
    .descr-review-product .review img {
        width: 60px;
        height: 60px;
    }
    .descr-review-product .review-detalis .top-review-details .rate-review span,
    .descr-review-product .review-detalis .top-review-details span,
    .descr-review-product .review-detalis .top-review-details p {
        font-size: 11px;
    }
    .descr-review-product .review-detalis .top-review-details {
        flex-wrap: wrap;
    }
    .descr-review-product .review-detalis .review-detalis-p {
        font-size: 10px;
        margin-bottom: 15px;
    }
    .product-details .fa {
        font-size: 13px;
    }
    .descr-review-product .review-detalis h6 {
        font-size: 13px;
    }
    .descr-review-product .review-detalis {
        padding: 15px;
    }
    .descr-review-product .review-detalis:after {
        left: -14px;
        top: 23px;
        border-left: 12px solid transparent;
        border-right: 10px solid transparent;
    }
    .descr-review-product .review-detalis:before {
        left: -16px;
        margin-top: 10px;
    }
    .shop-category {
        height: 200px;
    }

    .landing-page-img {
        height: 200px;
    }
    .product-details-rigth .product-options {
        justify-content: flex-start;
    }
    .moreProdDetails {
        text-align: center;
    }
    .form-control__phone-countryCode {
        width: 68% !important;
    }
    .input-group-text-phone-countryCode {
        width: 13%;
    }
}
@media only screen and (max-width: 425px) {
    .Header .user-signin .btn-primary h6 {
        font-size: 11px;
    }
    .Header .user-signin p {
        font-size: 10px;
    }
    .language-dropdown .dropdown-toggle img {
        width: 20px;
    }

    .language-name {
        font-size: 10px;
    }
    .Header .user-header img {
        width: 20px;
    }
    /* .no-padUnder-1500{
    padding: 0px !important;
    padding-left: 5px !important
  } */
    .Header .input-group > .custom-select:not(:first-child),
    .Header .input-group > .form-control:not(:first-child) {
        font-size: 10px;
    }
    .logo img {
        margin-top: -6px;
    }
    .toggle-header #react-sidebar .sidebar-main {
        width: 200px;
    }
    .toggle-header span {
        font-size: 10px;
        margin-left: 0px;
    }
    .toggle-span {
        display: none;
    }
    .bottom-header-content ul li a {
        font-size: 9px;
    }
    .bottom-header-content ul li {
        margin: 0px 2px;
    }
    .toggle-header button {
        padding: 0 5px;
    }
    .toggle-header button img {
        width: 20px;
    }
    .Header .dropdown-menu {
        font-size: 10px;
    }
    .title-section h4 {
        font-size: 12px;
    }

    /* home  */
    .collection-item p {
        padding-bottom: 10px;
        font-size: 11px;
    }
    .padd-5-mobile {
        padding-right: 10px !important;
        padding-left: 10px !important;
    }
    .joinUs {
        height: 150px;
    }
    .joinUs h4 {
        font-size: 20px;
    }
    .joinUs p {
        font-size: 12px;
    }
    .joinUs a {
        padding: 5px 25px;
        margin-top: 0px;
        font-size: 12px;
    }
    .socialmedia-follow .follow_item span:first-child {
        font-size: 7px;
    }
    .socialmedia-follow .follow_item span:last-child {
        font-size: 11px;
    }
    .socialmedia-follow .follow_item .fa {
        font-size: 25px;
    }
    .socialmedia-follow .follow_item div {
        margin-left: 5px;
    }
    .joinUs {
        margin: 25px 0;
    }
    .socialmedia-follow {
        margin: 50px 0;
    }
    .footer .footer-contact ul li a,
    .footer .footer-category ul li a,
    .footer .footer-account ul li a {
        font-size: 10px;
    }
    .copy-write {
        padding-top: 10px;
    }
    .copy-write ul li {
        margin: 0 3px;
        width: 25px;
    }
    .copy-write div {
        font-size: 8px;
    }
    .footer .footer-logo {
        width: 100%;
        margin: 0;
    }
    .footer .footer-logo ul li p {
        font-size: 7px;
    }
    .footer .footer-logo ul li {
        margin-right: 10px;
    }
    .filter .colors ul li {
        width: 15%;
    }
    .filter .colors ul li div div {
        height: 20px;
        width: 20px;
    }
    .whatsAppButton,
    .footer-prodDetails .addToCartDetalis {
        margin: 0;
        /* padding: 3px 5px; */
        font-size: 15px;
    }

    .footer-prodDetails div span {
        font-size: 11px;
    }
    /* .product-details-rigth .product-options ul li:last-child select {
    width: 85px;
  } */
    .footer-prodDetails div .fa {
        margin-right: 3px;
    }
    .product-details-rigth h4 {
        font-size: 18px;
    }
    .product-details-rigth p {
        font-size: 12px;
    }
    .footer-prodDetails p {
        font-size: 17px;
    }
    .related-product .col-12 {
        min-width: 235px !important;
        /* max-width: 320px; */
        max-width: 280px !important;
    }
    .related-product .row {
        justify-content: center;
    }
    .related-product h2 {
        color: #1a9c8c;
        margin-bottom: 20px;
        font-size: 22px;
    }
    .review-reply {
        flex-wrap: wrap;
    }
    .descr-review-product .nav-tabs .nav-link {
        padding: 10px 20px;
        font-size: 14px;
    }

    .Header .user-signin p {
        width: 76px;
        width: auto;
    }
}
@media only screen and (max-width: 475px) {
    .Header .user-signin .btn-primary h6 {
        margin: 0;
        font-size: 9px;
    }
    .toggle-header button img {
        width: 18px;
    }
    .bottom-header-content-handmade ul,
    .bottom-header-content ul {
        padding-left: 3px;
    }
    .related-product .product button,
    .related-product .product .product-namePrice span,
    .search-page .products .product button,
    .search-page .product .product-namePrice span {
        font-size: 12px;
    }
    .container-fluid {
        padding-right: 3px !important;
        padding-left: 3px !important;
    }
    .ProfileSubmit .btn-primary,
    .form-control,
    label,
    span {
        font-size: 9px;
    }
    .table > caption + thead > tr:first-child > td,
    .table > caption + thead > tr:first-child > th,
    .table > colgroup + thead > tr:first-child > td,
    .table > colgroup + thead > tr:first-child > th,
    .table > thead:first-child > tr:first-child > td,
    .table > thead:first-child > tr:first-child > th,
    .table-responsive > .table > tbody > tr > td,
    .table-responsive > .table > tbody > tr > th,
    .table-responsive > .table > tfoot > tr > td,
    .table-responsive > .table > tfoot > tr > th,
    .table-responsive > .table > thead > tr > td,
    .table-responsive > .table > thead > tr > th {
        font-size: 11px;
    }
    
    .Profile .table .MapA {
        font-size: 10px;
    }
    .product-details-rigth .product-options .priceProduct {
        font-size: 16px;
        margin-left: 0px;
    }
    .product-details-rigth .product-options {
        justify-content: flex-start;
    }
    .product-details-rigth .product-options ul li select {
        max-width: 180px;
    }

    /* .image-gallery{
  height: 200px;
} */

    .product-details-rigth {
        padding: 20px 10px;
    }
    .desc_review {
        padding: 25px 50px 0 50px;
        font-size: 14px;
    }
    .desc_review p {
        font-size: 14px;
    }
    .product-details .review-form label {
        font-size: 14px;
    }
    .react-multiple-carousel__arrow {
        background-color: transparent !important;
    }
    .addCartWish-other {
        width: auto;
    }
    .search-page .otherShow .addCartWish-other button {
        width: 35%;
    }
    .Header .user-signin .nameUser {
        width: 75px;
    }
    .filter h4 {
        font-size: 20px;
    }
    .filter .rate p,
    .filter .size p,
    .filter .colors p,
    .filter .price p {
        font-size: 19px;
    }
    .title-section {
        padding: 20px 0;
    }
    .specialOrderButton a {
        margin: 0;
        max-width: 120px;
        font-size: 14px;
        padding: 5px;
    }
    .Or {
        font-size: 16px;
    }
    .form-control__phone-countryCode {
        width: 64% !important;
    }
    .input-group-text-phone-countryCode {
        width: 17%;
    }
}

.cat-name {
    font-size: 25px;
    font-family: "RegularBodyRoman";
    color: #229d8c;
    margin: 0;
}

.zip-code-container {
    background-color: #f5f4f4 !important;
    color: #229d8c !important;
    border: none;
    font-size: 12px !important;
    border: 1px solid #ced4da !important ;
}

.phone-input-container .form-control {
    padding: 0 10px !important;
}

@media only screen and (max-width: 475px) {
    .zip-code-container {
        font-size: 9px !important;
    }
    .phone-input-container .phone-input {
        font-size: 9px !important;
        min-height: 23px !important;
    }
    .Header .padd-bot {
        padding-bottom: 10px;
        flex-direction: column;
        align-items: center;
        gap: 4px;
    }
    .Header .cartLangAcc {
        display: flex;
        justify-content: space-around;
        align-items: center;
        row-gap: 10px;
    }
    .user-header {
        margin: 0 !important;
    }
    .logo img {
        object-fit: scale-down;
        width: unset;
    }
    .logo {
        justify-content: center;
    }
}

.phone-input {
    min-height: 30px !important;
}
.Header .input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
    border: 1px solid #ced4da !important;
    margin: 0 !important;
    /* min-height: inherit !important; */
}
.cart-item__title {
    display: -webkit-box;
    height: 46px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.reg-button:focus {
    background-color: #1a9c8c !important;
}

.reg-button:hover {
    background-color: #fff !important;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row !important;
    font-size: 24px !important;
    padding: 10px 22px;
}

.modal-content {
    border-radius: 13px !important;
}
.modal-header button span {
    font-size: 30px !important;
}
.modal-body {
    max-height: 700px !important;
    overflow-y: scroll;
    text-align: left;
}
.productDetails-ModalBody {
    text-align: center;
}
.aizesGuidesBtn {
    color: #229d8c;
    padding: 10px 12px !important;
    cursor: pointer;
    border: 1px solid #229d8c;
    transition: 0.3s;
}

.aizesGuidesBtn:hover {
    transition: 0.3s;
    background-color: #229d8c;
    color: #fff;
}
.modal-body-Verification {
    text-align: right;
}

.profile-page-container {
    font-family: "Regular" !important;
}

.modal-map-header {
    display: flex !important;
    justify-content: space-between !important;
}

.modal-map-header h5 {
    width: 100% !important;
    display: flex;
    justify-content: flex-end;
}

.modal-map-header button {
    width: 100% !important;
    display: flex;
    justify-content: flex-start;
}

.modal-map-header button {
    margin-right: 0 !important;
}

.modal-body-Verification::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
}

.modal-body-Verification ::-webkit-scrollbar {
    width: 0 !important;
}
